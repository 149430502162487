
.pos-sales-container {
    display: block;
    padding: 10px 3rem;
    // overflow: hidden;
    height: 100%;

    @media (max-width: 767px) {
            padding: 0px;
        }

    .page-url-cont {
        display: block;
        padding: 10px;

        .page-url {
            display: flex;
            align-items: center;

            span {
                font-size: 15px;
                font-weight: 500;
                padding: 0 5px 0 0;
            }

            .slash {
                color: rgb(204, 204, 204);
            }

            .colored {
                color: #00d890;
                cursor: pointer;
            }

            .mainurl {
                color: #000000;
            }
        }
    }

    .column1 {
        display: flex;
        width: 100%;
        justify-content: right;
        align-items: center;

        @media (max-width: 767px) {
            justify-content: center;
        }

        .each-box {
            padding: 20px 40px;
            background-color: rgb(6, 112, 57);
            box-shadow: 0 8px 13px rgba(0, 0, 0, 0.116);
            text-align: center;
            border-radius: 10px;

            .box-head {
                color: rgb(225, 225, 225);
            }

            .box-content {
                color: rgb(255, 255, 255);
                font-size: 25px;
                font-weight: 600;
                margin-top: 10px;
            }
        }
    }

    .preview-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .dark-overlay {
            background-color: rgba(0, 0, 0, 0.219);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
        }

        .preview-inner-container {
            background-color: white;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.24);
            // margin: 20px 0px 10px 0px;
            padding: 20px;
            width: 90%;
            border-radius: 5px;
            display: block;
            position: relative;
            z-index: 12;
            // top: 30%;
            overflow: scroll;

            @media only screen and (max-width: 680px) {
                    max-width: 100%;
                    width: 100%;
                    padding: 20px;
                    height: 100vh;
                }

            .closeBtn {
                display: flex;
                justify-content: right;
                cursor: pointer;

                @media only screen and (max-width: 680px) {
                        svg {
                            width: 30px;
                            height: 30px;
                        }
                    }
            }

            ul li {
                margin-top: 10px;
                width: 100%;
                    text-align: left;
                
                    @media only screen and (max-width: 680px) {
                        text-align: center;
                        padding: 0;
                        margin: 0;
                    font-size: 16px;
                
                    }
            }

            .table-responsive {
                display: block;
                width: 100%;
                overflow-x: auto;
                border: 0 solid #ebeff2;
                overflow: auto;

                table {
                    border-spacing: 0;
                    border-collapse: collapse;

                    tbody tr .text-center {
                        text-align: center;

                        .font-bold {
                            font-weight: 600;
                        }

                    }

                    tbody tr {
                        border-bottom: 1px rgb(158, 158, 158) solid;
                        margin: 20px 0;
                    }

                    .action-btn {
                        color: white;
                        background-color: rgb(19, 128, 55);
                        border-radius: 10px;
                        // width: 40%;
                        margin: 0 auto;
                        cursor: pointer;
                        font-size: 15px;
                        padding: 10px 0;

                        &:hover {
                            background-color: red;
                        }
                    }
                }

                .table {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 20px;
                    text-align: center;

                    .thead-light th {
                        color: #6c757d;
                        background-color: #f8f6ff;
                        border-color: #dee2e6;
                        white-space: nowrap;
                        padding: 8px;
                        line-height: 1.42857143;
                        border: 0 !important;
                    }
                }
            }
        }

    }

    .pos-sales-content-container {
        background-color: white;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.24);
        margin: 20px 0px 10px 0px;
        padding: 20px 0 20px 0;
        width: 100%;
        border-radius: 5px;
        display: block;
        position: relative;
        // height: 400px;
        min-height: 400px;
        overflow-x: hidden;


        .tab-controls {
            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0;

                @media (max-width: 768px) {
                        display: block;
                    }

                li {
                    list-style: none;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;

                    input {
                        // border: 1px solid black;
                        margin-right: 15px;
                        box-shadow: 0 0px 4px rgba(0, 0, 0, 0.24);
                        border-radius: 5px;
                        padding: 5px;
                        font-size: 15px;
                    }

                    div {
                        padding-right: 20px;
                    }

                    span {
                        font-size: 14px;
                        font-weight: 600;
                        color: #686868;
                        text-transform: uppercase;
                        padding-bottom: 6px;
                        transition: all .4s ease-in-out;
                        cursor: pointer;

                        &:hover {
                            color: #00bb8c;
                        }
                    }

                    .tab1.active {
                        border-bottom: 4px solid #007558;
                        border-bottom-left-radius: 2px;
                        border-bottom-right-radius: 2px;
                        color: #00bb8c;
                    }
                }
            }

        @media (max-width: 768px) {
            .li-flex {
                display: block;
                margin-top: 10px;
                    align-items: baseline;

                div {
                        padding-right: 5px;
                    }
            }

            
        }

            select{
                margin-right: 15px;
                    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.24);
                    border-radius: 5px;
                    padding: 5px;
                    font-size: 15px;

                    @media (max-width: 768px) {
                    
                                margin-right: 0px;
                        }
            }

            .refresh_btn {
                // position: absolute;
                // right: 20px;
                // cursor: pointer;
                background-color: #eaeaea;
                border-radius: 100px;
                padding: 5px;
                cursor: pointer;
                transition: all .2s ease-in-out;

                &:hover {
                    background-color: #aaaaaa;
                }

                                // @media (max-width: 768px) {
                                //     width: 35px;
                                // }
            }
        }

        .tab-content {
            color: #777;
            padding: 20px !important;

            .tab-pane.active {
                display: none;
            }

            .tab-pane {
                display: block;

                .table-responsive {
                    display: block;
                    width: 100%;
                    overflow-x: auto;
                    border: 0 solid #ebeff2;
                    overflow: auto;

                    table {
                        border-spacing: 0;
                        border-collapse: collapse;

                        tbody tr .text-center {
                            text-align: center;

                            .center {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                }

                            .font-bold {
                                font-weight: 600;
                            }

                        }

                        tbody tr {
                            border-bottom: 1px rgb(158, 158, 158) solid;
                            margin: 20px 0;
                        }

                        .action-btn {
                            color: white;
                            background-color: rgb(19, 128, 55);
                            border-radius: 10px;
                            // width: 40%;
                            margin: 0 auto;
                            cursor: pointer;
                            font-size: 15px;
                            padding: 10px 0;

                            &:hover {
                                background-color: red;
                            }
                        }
                    }

                    .table {
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 20px;

                        .thead-light th {
                            color: #6c757d;
                            background-color: #f8f6ff;
                            border-color: #dee2e6;
                            white-space: nowrap;
                            padding: 8px;
                            line-height: 1.42857143;
                            border: 0 !important;
                        }
                    }
                }
            }
        }
    }


}