.user-manager-cont {
  display: block;
  padding: 10px 3rem;
  height: 500px;

  @media only screen and (max-width: 680px) {
      padding: 0px;
    }

  .btn {
    display: block;
    width: 30%;
    border-radius: 10px;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 300;
    text-transform: capitalize;
    color: white;
    background: #357a6b;
    padding: 1rem 10px;
    margin: 20px auto;
    border: 0;
    outline: none;
    cursor: pointer;
    // z-index: 1;
    transition: all .25s ease;

    @media only screen and (max-width: 680px) {
      font-size: 1.5rem;
    }
  }

  .btn.active {
    display: none;
  }

  .btn:hover {
    background: #52ab98;
  }

  .page-url-cont {
    display: block;
    padding: 10px;

    .page-url {
      display: flex;
      align-items: center;

      span {
        font-size: 15px;
        font-weight: 500;
        padding: 0 5px 0 0;
      }

      .slash {
        color: rgb(204, 204, 204);
      }

      .colored {
        color: #00d890;
        cursor: pointer;
      }

      .mainurl {
        color: #000000;
      }
    }
  }

  .usermanager-content {
    background-color: white;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
    margin: 20px 0px 10px 0px;
    padding: 20px 0 20px 0;
    width: 100%;
    border-radius: 5px;
    display: block;

    .head {
      padding: 10px;
      font-weight: 600;
      font-size: 20px;
      color: rgb(158, 158, 158);
    }

    .user-manager-content {
      display: flex;
      padding: 10px;
      justify-content: space-evenly;


      .employee-list {
        width: 100%;

        table {
          border-spacing: 0;
          border-collapse: collapse;

          tbody tr .text-center {
            text-align: center;

            .center {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }

            .font-bold {
              font-weight: 600;
            }
          }
        }

        .table {
          width: 100%;
          max-width: 100%;
          margin-bottom: 20px;

          .thead-light th {
            color: #6c757d;
            background-color: #1a776353;
            border-color: #dee2e6;
            white-space: nowrap;
            padding: 8px;
            line-height: 1.42857143;
            border: 0 !important;
          }
        }

        .action-btn{
          margin: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

                 @media only screen and (max-width: 680px) {
                         display: block;
                        }
        }

           .delete-btn {
            color: white;
            background-color: rgb(255, 78, 78);
            border-radius: 10px;
            width: 40%;
            margin: 0 auto;
            cursor: pointer;
            font-size: 15px;
            padding: 10px 0;
           
            &:hover {
              background-color: red;
            }
            
                @media only screen and (max-width: 680px) {
                     margin: 5px auto;
                     padding: 10px;
                     width: 100%;
                }
           }
        
           .edit-btn {
            color: white;
            background-color: rgb(252, 189, 36);
            border-radius: 10px;
            width: 40%;
            margin: 0 auto;
            cursor: pointer;
            text-align: center;
            font-size: 15px;
            padding: 10px 0;
           
            &:hover {
              background-color: rgba(252, 187, 36, 0.797);
            }
            
                      @media only screen and (max-width: 680px) {
                          padding: 10px;
                          width: 100%;
                        }
          }
        }

        
    }

    .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.popup-card {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;

    p {
        margin-bottom: 20px;
    }

    .popup-buttons {
        display: flex;
        justify-content: center;

        .confirmDel {
            padding: 10px 20px;
            margin: 0 10px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-weight: bold;
        }

        .confirmDel:nth-child(1) {
            background-color: rgb(255, 78, 78);
            color: #fff;
        }

        .confirmDel:nth-child(2) {
            background-color: #357a6b;
            color: #fff;
        }
    }
}

    .addnewuser-container.active {
      display: none;
    }

    .addnewuser-container {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;

      .overlay {
        background-color: rgba(0, 0, 0, 0.387);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
      }

      .addnewuser-inner-container {
        position: fixed;
        top: 20%;
        width: 40%;
        // left: 20%;
        border-radius: 10px;
        margin: 0 auto;
        background-color: white;
        box-shadow: 0 10px 14px rgba(0, 0, 0, 0.24);
        padding: 10px 10rem;

        @media only screen and (max-width: 680px) {
          width: 90%;
          padding: 10px 5rem;

          .err li {
              font-size: 17px;
            }
        }
      }

      .other-settings {
        .each-input {
          display: block;
          justify-content: center;
          margin-top: 20px;

          .input-heading {
            font-size: 14px;
            font-weight: 600;
            color: #009c68;
          }

          .red {
            color: red;
            font-weight: 500;
          }

          .form-flex-cont {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
              display: block;

              .form-flex {
                margin-top: 8px;
              }
            }
          }

          .form-flex {
            border: 1.2px solid #235e51;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin-top: 5px;

            select {
              border: none;
              padding: 12px;
              font-size: 16px;
              -moz-appearance: none;
              -webkit-appearance: none;
              width: 100%;
              background-color: transparent;
              background: transparent;

              option {
                margin: 10px 0;
              }
            }

            select::-ms-expand {
              display: none;
              border: none;
            }

            span {
              padding: 12px;
              font-weight: 700;
              color: #235e51;
            }

            .visibility-btn {
              width: 43px;
              height: 44px;
              background: #3381705d;
              align-items: center;
              display: flex;
              justify-content: center;
              border: none;

              .toggle-Icn {
                width: 100%;
                display: flex;
                justify-content: center;

                .fa1 {
                  display: inline-block;
                  font: normal normal normal 14px/1 FontAwesome;
                  font-size: inherit;
                  text-rendering: auto;
                  -webkit-font-smoothing: antialiased;
                  width: 14px;
                  height: 14px;
                  opacity: 0.7;
                }

                .fa1.active {
                  display: none;
                }
              }
            }
          }
        }
      }

      .inner-btns-flex {
        display: flex;
        justify-content: space-around;

        @media only screen and (max-width: 680px) {
          width: 100%;
          justify-content: space-between;
        }
      }
    }


  }
}