* {
  max-width: 1500px;
  text-align: center;
}

.hero-main-container {
  .major {
    margin: 0.5rem auto;
    text-transform: none;
    width: 45%;
    text-align: center;
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 50px;
  }

  .text {
    color: #d1cfcf;
    margin-block: 1rem;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-block-start: 4rem;
  }

  .free {
    border: 2px solid grey;
    font-size: 1.5rem;
    width: fit-content;
    margin-inline: auto;
    border-radius: 20px;
    padding-inline: 6rem;
    padding-block: 0.5rem;
    color: white;
    background-color: #054a49;
    font-weight: 600;
  }

  .app {
    border: 2px solid grey;
    font-size: 1.5rem;
    width: fit-content;
    margin-inline: auto;
    border-radius: 20px;
    padding-inline: 4rem;
    padding-block: 0.5rem;
    color: black;
    font-weight: 600;
  }

  .dashboard {
    margin-inline: auto;
    margin-block: 5rem;
    width: 70%;
  }

  .cards {
    width: 80%;
    margin-inline: auto;
    margin-block: 15rem;
    display: flex;
    justify-content: center;
    gap: 2rem;

    .one {
      background-color: #eae7ff;
      border-radius: 20px;
      width: 30%;
      padding: 4rem;

      h2 {
        text-transform: none;
        margin-block: 1rem;
        font-weight: 700;
        font-size: 1.5rem;
        text-align: center;
      }

      p {
        font-size: 1.2rem;
        color: #202020;
      }
    }

    .second {
      background-color: #f1f5f9;
    }

    .third {
      background-color: #f8ede3;
    }
  }

  .first_feature {
    display: flex;
    align-items: start;
    padding: 6rem;
  }

  .first_feature_text {
    text-align: start;

    h2 {
      width: 45%;
      line-height: 40px;
      margin-block: 3rem;
      font-weight: 600;
      font-size: 3.5rem;
    }

    p {
      width: 60%;
      text-align: start;
      margin-block: 3rem;
      color: #d1cfcf;
    }
  }

  .second_feature {
    padding: 6rem;

    h2 {
      width: 40%;
      text-transform: none;
      margin-block: 3rem;
      font-weight: 600;
      font-size: 4rem;
      line-height: 40px;
    }

    p {
      text-align: start;
      width: 40%;
      font-size: 1.5rem;
      color: #a7a5a5;
    }

    .image {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      margin-block: 4rem;
      padding: 1rem;
      display: flex;

      .divide {
        width: 20px;
        background-color: #f1f6f5;
      }

      .first_bar {
        .first_bar_title {
          background-color: #f8ede3;

          h2 {
            font-size: 1.6rem;
            text-align: center;
            margin-inline: auto;
            width: 50%;
            margin-block: 0.1rem;
          }
        }

        .two {
          background-color: #f1f6f5;
        }

        .three {
          background-color: #fffae7;
        }

        .four {
          background-color: #f0fff9;
        }

        .first_bar_contents {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: start;
          margin-block: 0.5rem;
          background-color: #f9f9f9;
          padding: 1rem;

          p {
            font-size: 1.2rem;
            width: 90%;
          }
        }
      }
    }
  }

  .third_feature {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-inline: auto;
    background-color: #054a49;
    padding: 2rem;

    .third_feature_first {
      display: flex;
      justify-content: space-between;
      color: white;

      h2 {
        width: 30%;
        text-transform: none;
        margin-block: 4rem;
        color: white;
        font-weight: 600;
      }

      p {
        width: 55%;
        text-align: right;
        margin-block: 4rem;
        color: rgb(218, 209, 209);
      }
    }

    .card {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
    }

    .payment {
      width: 30%;
      padding: 1rem;
      background-color: #fffae7;
      border-radius: 8px;

      img {
        width: 30px;
        height: 30px;
        margin-block: 1rem;
      }

      h3 {
        margin-block: 1rem;
        text-align: start;
      }

      p {
        text-align: start;
        font-size: 1.2rem;
        width: 90%;
      }
    }

    .invoice {
      background-color: #f1f5f9;
    }

    .customer {
      background-color: #f1f6f5;
    }
  }

  .go-global {
    margin-block: 5rem;

    h3 {
      font-weight: 600;
      font-size: 4rem;
    }

    p {
      color: #151515;
      width: 60%;
      margin-inline: auto;
    }

    .map {
      background-image: url(../../assets/Map.svg);
      background-repeat: no-repeat;
      background-size: cover;
      margin-block: 5rem;
    }
  }

  .management {
    display: flex;
    justify-content: space-between;
    padding: 6rem;
    flex-wrap: wrap;

    .management_text {
      width: 50%;
    }

    h2 {
      width: 70%;
      text-transform: none;
      margin-block: 2rem;
      font-weight: 600;
      font-size: 2.5rem;
    }

    h3 {
      text-align: start;
      color: #054a49;
    }

    .lists {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-block: 1rem;

      p {
        margin-block: 1rem;
        color: #a7a5a5;
      }
    }

    .rule {
      height: 4px;
      width: 100%;
      margin-block: 1rem;
    }
  }

  .contact-us {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: start;
    padding: 6rem;
    background-color: #054a49;
    color: white;
    align-items: center;
    background-image: url(../../assets/Ellipse.svg);
    background-repeat: no-repeat;
    background-position: right bottom;

    .contact-us_text {
      width: 50%;

      h2 {
        font-size: 4rem;
        line-height: 64px;
        font-weight: 600;
        color: white;
        text-transform: none;
        margin-bottom: 2.5rem;
      }

      h3 {
        margin-block: 1rem;
        text-align: start;
        font-size: 3.5rem;
        font-weight: 600;
        width: 60%;
        line-height: 60px;
      }
    }

    .text {
      width: 85%;
      text-align: start;
      color: #d1cfcf;
      margin-block: 5rem;
    }

    .last {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-top: 7rem;

      p {
        text-decoration: underline;
      }
    }

    button {
      background-color: white;
      padding: 1.8rem 7rem;
      border-radius: 30px;
      margin-inline: 0;
    }
  }

  .get-started {
    background-color: #054a49;
    width: 60%;
    margin-inline: auto;
    margin-block: 1rem;
    color: white;
    padding: 1rem;

    h2 {
      color: white;
      font-size: 3rem;
      text-align: center;
      margin-block-start: 3rem;
    }

    .get-started_text {
      color: #d1cfcf;
      width: 55%;
      font-size: 1.6rem;
      margin-inline: auto;
      margin-block-start: 1rem;
      margin-block-end: 3rem;
    }

    .get-started-check {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: center;
      margin-block: 3rem;
      flex-wrap: wrap;

      div {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.6rem;
      }
    }

    button {
      background-color: white;
      margin-block: 1rem;
      color: black;
      padding-inline: 10rem;
      padding-block: 1rem;
    }
  }

  @media screen and (max-width: 767px) {
    .major {
      width: 100%;
    }

    .first_feature {
      flex-direction: column;
    }

    .first_feature_text {
      h2 {
        width: 100%;
      }

      p {
        width: 100%;
      }
    }

    .cards {
      flex-direction: column;
      margin-block: 5rem;

      .one {
        width: 100%;
      }
    }

    .second_feature {
      h2 {
        width: 100%;
      }

      p {
        width: 100%;
      }

      .image {
        flex-direction: column;
      }
    }

    .third_feature {
      width: 90%;

      .third_feature_first {
        flex-direction: column;

        h2 {
          width: 100%;
        }

        p {
          width: 100%;
          text-align: left;
        }
      }

      .card {
        flex-direction: column;
      }

      .payment {
        width: 100%;
      }
    }

    .go-global {
      p {
        width: 100%;
      }
    }

    .management {
      h2 {
        width: 100%;
      }

      .management_text {
        width: 100%;
      }

      .lists {
        p {
          text-align: start;
        }
      }
    }

    .contact-us {
      background-image: none;

      img {
        display: none;
      }

      .contact-us_text {
        width: 100%;

        h2 {
          line-height: 42px;
        }

        h3 {
          width: 100%;
          line-height: 40px;
        }
      }

      .text {
        width: 100%;
      }

      .last {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .get-started {
      width: 90%;

      .get-started_text {
        width: 100%;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .major {
      width: 50%;
    }

    .first_feature {
      flex-wrap: wrap;
    }

    .first_feature_text {
      h2 {
        width: 100%;
      }

      p {
        width: 100%;
      }
    }

    .cards {
      width: 95%;

      .one {
        width: 35%;
        padding: 3rem;
      }
    }

    .management {
      .one {
        margin-inline: auto;
      }

      h2 {
        width: 100%;
      }

      .management_text {
        width: 100%;
      }
    }

    .second_feature {
      h2 {
        width: 100%;
      }

      p {
        width: 70%;
      }

      .image {
        flex-wrap: wrap;

        .divide {
          width: 0;
        }
      }
    }

    .third_feature {
      width: 90%;

      .third_feature_first {
        h2 {
          width: 35%;
        }
      }
    }

    .contact-us {
      .contact-us_text {
        width: 100%;

        h3 {
          width: 100%;
        }
      }
    }

    .go-global {
      p {
        width: 90%;
      }
    }
  }
}