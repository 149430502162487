.subscription_contianer {
    // padding: 0 3rem;
    // background-color: rgba(0, 0, 0, 0.643);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    overflow: scroll;

    .darkoverlay {
        background-color: rgba(0, 0, 0, 0.519);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }

    .subscription_contianer-inner {
        background-color: white;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
        border-radius: 20px;
        position: relative;
        padding-bottom: 20px;

        @media only screen and (max-width: 680px) {
            position: absolute;
            top: 0;
            border-radius: 0;
        }

        .header {
            background-color: rgb(208, 130, 12);
            padding: 10px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            display: flex;
            justify-content: center;

           .close_btn{
                display: none;
            }

            @media only screen and (max-width: 680px) {
                border-radius: 0;
            justify-content: space-between;

                .close_btn {
                        display: block;
                        margin-right: 10px;
                    }
            }

            p {
                text-align: center;
                color: white;
            }
        }

        .col-1 {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 20px;


            .each-card {
                margin: 20px;
                background-color: rgb(6, 85, 48);
                // backdrop-filter: blur(7px);
                min-height: 372px;
                min-width: 272px;
                padding: 26px 22px 58px;
                text-align: center;
                border-radius: 10px;
                width: min-content;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                p,
                h2,
                button {
                    color: white;
                    text-align: center;
                }

                img {
                    width: 60px;
                    display: flex;
                    margin: 0 auto;
                    margin-bottom: 10px;
                }

                .head {
                    padding-bottom: 8px;
                    text-align: left;
                    font-size: 15px;
                }

                span {
                    display: flex;
                }

                .title {
                    // margin: 0 10px;
                    font-size: 40px;
                    padding-bottom: 24px;
                    text-transform: none;
                }

                .desc {
                    font-size: 12px;
                    margin: 0 10px;
                }

                .button-flex {
                    width: 100%;
                    margin-top: 10px;

                    .activeplan {
                        p {
                            background-color: rgb(33, 142, 149);
                            padding: 10px 16px;
                            color: #fff;
                            border-radius: 10px;
                            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
                        }

                        .p2 {
                            margin-top: 10px;
                            background-color: transparent;
                            padding: 10px 16px;
                            color: #f5ca3b;
                            border-radius: 10px;
                            box-shadow: none;
                        }
                    }

                    .button-flex-inner {
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        width: 100%;
                    }

                    button {
                        background-color: white;
                        color: rgb(6, 85, 48);
                        padding: 5px 10px;
                        border-radius: 5px;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }

                &:hover {
                    background-color: rgb(6, 129, 96);
                }

            }
        }

        .close-btn {
            display: flex;
            justify-content: center;

            button {
                background-color: rgb(6, 85, 48);
                color: rgb(255, 255, 255);
                padding: 5px 10px;
                border-radius: 5px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }



}