.backtotop{
    position: fixed; 
    z-index: 50000;
   right: 30px;
   bottom: -100px;
   height: 30px;
   width: 30px;
   font-size: 3rem;
   border-radius: 50%;
   z-index: 1;
   cursor: pointer;
   color: white;
   justify-content: center;
   align-items: center;
   background-color: #2b6777;
   animation-name: moveBckToBtm;
   animation-duration: 1s;
   animation-timing-function: ease;
//    animation-delay: 0.5s;
   animation-iteration-count: 1;
   animation-direction: normal;
   animation-fill-mode: backwards;
   display: flex;
}

.backtotop.active{
    bottom: 80px;
    display: flex;
    animation-name: moveInFromBtm;
   animation-duration: 1s;
   animation-timing-function: ease;
//    animation-delay: 0.5s;
   animation-iteration-count: 1;
   animation-direction: normal;
   animation-fill-mode: backwards;
}