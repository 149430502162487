.hero-main-container {
  .major {
    width: 40%;
  }

  .feature {
    background-color: #054a49;
    padding: 6rem;

    .features_first {
      display: flex;
      align-items: center;
      text-align: start;
      color: white;
      justify-content: space-between;

      h2 {
        width: 30%;
        color: white;
        text-transform: none;
      }

      p {
        font-size: 1.2rem;
        color: #d1cfcf;
      }
    }
  }

  .cards_about {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-block: 10rem;
    flex-wrap: wrap;
    justify-content: center;

    .card {
      background-color: #fffae7;
      padding: 3rem;
      width: 30%;
      border-radius: 9px;

      img {
        margin-bottom: 2rem;
        width: 50px;
        height: 50px;
      }

      h3 {
        text-align: start;
        font-size: 1.8rem;
        color: #151515;
        font-weight: 700;
        margin-bottom: 3rem;
      }

      p {
        text-align: start;
        color: #9e9e9e;
        font-size: 1.4rem;
        margin-bottom: 4rem;
      }
    }

    .next {
      background-color: #f1f5f9;
    }

    .upper {
      background-color: #f1f6f5;
    }
  }

  .support {
    width: 30%;
    margin-inline: auto;
    margin-block: 10rem;

    h2 {
      text-align: center;
      text-transform: none;
      font-weight: 700;
      font-size: 3rem;
    }

    h3 {
      font-weight: 700;
      font-size: 2.5rem;
      margin-top: 3rem;
      margin-inline: auto;
    }

    .last {
      display: flex;
      margin-top: 4rem;
      align-items: center;
      justify-content: center;
      gap: 2rem;

      button {
        background-color: black;
        color: white;
        margin-inline: 0;
        padding-block: 1rem;
      }

      p {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .major {
      width: 100%;
    }

    .feature {
      .features_first {
        flex-direction: column;

        h2 {
          width: 100%;
          text-align: center;
          margin-bottom: 1rem;
        }
      }
    }

    .cards_about {
      flex-direction: column;

      .card {
        width: 100%;
      }
    }

    .support {
      width: 80%;
    }

    .last {
      flex-direction: column;
    }
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .support {
      width: 40%;
    }

    .cards {
      justify-content: center;
    }

    .features {
      .features_first {
        h2 {
          width: 40%;
        }
      }
    }

    .major {
      width: 60%;
    }
  }
}
