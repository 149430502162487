.gt_strt-container {
    display: flex;
    justify-content: center;
    background-color: #0f362e;
    height: 100vh;
    // 52ab98

    .left-clm {
        width: 60%;
        height: 100%;
        padding: 3rem;

        @media only screen and (max-width: 680px) {
            display: none;
        }

        .logo {
            display: flex;
            align-items: center;

            img {
                width: 30%;
            }

            h1 {
                color: white;
                font-weight: 900;
                margin-left: 20px;
            }
        }

        .left-center {}
    }

    .right-clm {
        background-color: #52ab98;
        width: 40%;
        // height: 100%;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;

        .back_btn {
            position: absolute;
            top: 0;
            left: 0;
            padding: 10px;

            a {
                cursor: pointer;

                svg {
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        width: 35px;
                        height: 35px;
                    }
                }
            }
        }

        @media only screen and (max-width: 680px) {
            width: 100%;
            height: 100vh;
        }

        .right-clm-inner {
            // text-align: center;
            width: 100%;

            h1 {
                text-align: center;
                text-transform: capitalize;
                color: white;
                font-weight: 900;
            }

            .btn_flex {
                display: flex;
                justify-content: space-around;
                margin-top: 30px;

                button {
                    background-color: #0f362e;
                    padding: 10px 20px;
                    color: white;
                    border-radius: 10px;
                    width: 40%;
                }
            }

            .right-clm-btm {
                position: absolute;
                text-align: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                // z-index: 10;
                margin: 0 auto;
                bottom: 1rem;
                left: 0;
                // left: 50%;


                .logo {
                    text-align: center;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;

                    p {
                        color: white;
                        margin-left: 10px;
                    }

                    img {
                        width: 20%;

                        @media only screen and (max-width: 680px) {
                            width: auto;
                        }

                    }
                }

                .flex {
                    // padding: 0 20px ;
                    // margin: 0 20px ;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin-top: 20px;

                    span {
                        a {
                            font-size: 15px;
                        }

                        @media only screen and (max-width: 680px) {
                            a {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}