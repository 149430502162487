.downloads_contianer_main {
    padding: 30px 0px;

    .downloads_contianer {
        border-radius: 30px;
        background-color: rgb(29, 175, 141);
        box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.258);
    }

    .col-1 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 40px 70px;
        margin: 0px 20px 20px 20px;

        @media only screen and (max-width: 680px) {
            display: block;
            padding: 20px;
            margin: 20px;
        }

        .left {
            width: 35%;
            text-align: left;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            // margin: 0 auto;



            h1 {
                font-size: 60px;
                line-height: 1;
                text-decoration: none;
                font-style: normal;
                text-transform: none;
                color: white;
            }

            @media only screen and (max-width: 680px) {
                display: block;
                width: 100%;

                h1 {
                    font-size: 40px;
                    text-align: center;
                }
            }
        }

        .right {
            width: 43%;
            color: white;
            text-align: left;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;

            @media only screen and (max-width: 680px) {
                display: block;
                width: 100%;
                padding: 20px;
                margin: 20px;
            }

            div {
                padding-bottom: 24px;

                @media only screen and (max-width: 680px) {
                    padding-bottom: 34px;
                }
            }

            p {
                font-size: 18px;

                @media only screen and (max-width: 680px) {
                    font-size: 14px;
                }
            }

            span {
                font-size: 14px;
                padding-top: 24px;
            }


            a {
                color: blue;

                @media only screen and (max-width: 680px) {
                    font-size: 14px;
                }
            }
        }
    }

    .col-2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .each-card {
            margin: 20px;
            background-color: rgb(6, 85, 48);
            // backdrop-filter: blur(7px);
            min-height: 372px;
            min-width: 252px;
            padding: 26px 22px 58px;
            text-align: center;
            border-radius: 10px;
            width: min-content;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            p,
            h2,
            button {
                color: white;
                text-align: center;
            }

            .head {
                padding-bottom: 8px;
            }

            .title {
                // margin: 0 10px;
                font-size: 48px;
                padding-bottom: 24px;
                text-transform: none;
            }

            .desc {
                font-size: 12px;
                margin: 0 10px;
            }

            button {
                background-color: white;
                color: rgb(6, 85, 48);
                padding: 10px 20px;
                border-radius: 10px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    transform: scale(1.05);
                }
            }

            &:hover {
                background-color: rgb(6, 129, 96);
            }

        }
    }

    .col-3 {
        background-color: white;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 90px 50px;
        display: flex;
        flex-direction: row;
        // align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @media only screen and (max-width: 680px) {
            display: block;

        }

        .left {
            // width: 40%;
            text-align: center;

            

            h1 {
                text-transform: none;
                font-size: 60px;
                line-height: 1;
            }

            p {
                font-size: 18px;
                margin: 18px 0;
            }

            @media only screen and (max-width: 680px) {
                width: 100%;

                h1 {
                    font-size: 40px;
                    text-align: center;
                }
    
            }
        }

        .right {
            // width: 40%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

           

            .each-card {
                margin: 10px;
            box-shadow: 0 0 13px rgba(0, 0, 0, 0.311);


                img {
                    width: 100%;
                    height: 400px;
                    object-fit: contain;
                    // border-radius: 8px;
                }
            }

            @media only screen and (max-width: 680px) {
                display: block;
                width: 100%;

            }
        }
    }
}