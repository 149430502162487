.recoverBackground {
 display: flex;
}

.mainRecover{
    width: 50%;
    background-color: white;
    height: 100vh;
    padding: 10px 100px;
}

.emptyRecover{
    width: 50%;
}

.RecoverLogo img{
    width: 100px;
    height: 100px;
}

.RecoverHead{
    font-size: 50px;
    font-weight: bold;
}

.recoverTxt{
  font-weight: bold;
}

.input-header{
  margin-top: 30px;
  font-size: 20px;
}

.input-field{
  margin-top: 20px;
}

.input-field input{
    width: 100%;
    border: 2px solid black;
    color: black;
    padding: 5px;
    border-radius: 5px
}


.message{
  background-color: rgb(111, 206, 111);
  font-size: 15px;
  text-align: center;
  padding: 10px;
  border-radius: 5px
}

.dha{
  text-align: center;
  font-size: 18px;
  padding-top: 5px
}

  
  @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Poppins&display=swap');
  
  .loading-overlay{
    background-color: rgba(0, 0, 0, 0.699);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    
  
    .topprogressbar{
      border-top: 5px solid #009c75;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      position: fixed;
      width: 0%;
    height: 5px;
    top: 0;
    left: 0;
    animation: loading-width 10s infinite;
  
  
    @keyframes loading-width {
      0% {
        width:0%;
      }
      100% {
        width:100%;
      }
    }
    }
  
    .lds-hourglass {
      display: inline-block;
      width: 80px;
      height: 80px;
      position: absolute;
      left: 50%;
      top: 50%;
    }
    .lds-hourglass:after {
      content: " ";
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: 8px;
      box-sizing: border-box;
      border: 32px solid #fff;
      border-color: #fff transparent #009c75 transparent;
      animation: lds-hourglass 1.2s infinite;
    }
    @keyframes lds-hourglass {
      0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      100% {
        transform: rotate(1800deg);
      }
    }

  }
  
  .loading-overlay.active{
    display: none;
  }
  
  .formWrap {
    font-family:'Poppins', sans-serif;
    margin: 1rem auto 0;
    display: block;
    height: 100%;
  
    
  
    .logo-container{
      width: 100%;
      height: 100px;
      padding-left: 40px;
      display: flex;
      justify-content: left;
      padding-bottom: 20px;
      padding-left: 20px;
      .logo{
          display: flex;
          align-items: center;
         
          img{
              width: 100px;
              margin: 10px;
          }
      }
  }
  
    .txt{
      margin: 0px 0px 0px 20px;
      padding-bottom: 20px;
      // width: 100%;
      text-align: center;
      font-family:'Poppins', sans-serif;
  
      .head{
        font-size: 30px;
        position: relative;
        font-weight: 600;
        width: 100%;
        color: rgb(59, 59, 59);
      }
  
      .cont{
        text-align: center;
        margin-top: 25px;
        color: rgb(114, 114, 114);
        line-height: 1.6;
  
        span{
          width: 60%;
        }
      }
    }
  

      .passerr{
        color: #f05050;
        font-size: 12px;
        margin-top: 10px;
        font-weight: 500;
      }
  
      .passerr.active{
        display: none;
      }
  
      .form-flex-cont{
        display: flex;
        justify-content: space-between;
  
        @media screen and (max-width: 768px) {
          display: block;
          .form-flex{
            margin-top: 8px;
          }
        }
    
      
      .form-flex{
        border: 1.2px solid #235e51;
      border-radius: 5px;
        display: flex;
        align-items: center;
        margin-top: 5px;
  
        select{
          border: none;
          padding: 12px;
          font-size: 16px;
          -moz-appearance: none;
          -webkit-appearance: none;
          width: 100%;
          background-color: transparent;
          background: transparent;
  
          option{
            margin: 10px 0;
          }
        }
  
        select::-ms-expand{
          display: none;
          border: none;
        }
  
        span{
          padding: 12px;
          font-weight: 700;
          color: #235e51;
        }
  
        .visibility-btn{
          width: 43px;
          height: 44px;
          background: #3381705d;
          align-items: center;
          display: flex;
          justify-content: center;
          border: none;
  
          .toggle-Icn{
            width: 100%;
            display: flex;
            justify-content: center;
  
            .fa1{
              display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      width: 14px;
      height: 14px;
      opacity: 0.7;
            }
  
            .fa1.active{
              display: none;
            }
          }
        }
      }
    }
  }
  
  .span{
    display: contents;
    
  }
  
  .span.active{
    display: none;
  }
  
  .loader{
    display: none;
  }
  
  .loader.active{
    display: flex;
  }
  
  .links {
    display: block;
    width: 100%;
    margin: 2rem auto 0;
  
  
    a {
      color: black;
    }
  
    span{
      font-size: 15px;
    }
  }
  
  .links.center {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 4rem 0 8rem 0;
  
  
    a {
      color: black;
    }
  
    span{
      font-size: 15px;
    }
  }
  
  .flex{
    justify-content: space-between;
    text-align: right ;
    display: flex;
    padding: 0px 20px 20px 20px;
    
    a{
      font-weight: 300;
    }
  }
  
  @media only screen and (max-width: 980px) {
    .links a{
      font-size: 1.5rem;
    }
  }
  
  .err {
    li {
      list-style: none;
      color: rgb(255, 37, 37);
      text-align: center;
    }
  }