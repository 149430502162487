.adminLayout {
    background: #F4F6F8;
    display: flex;
    height: 100%;

    .subState {
        background-color: rgb(2, 73, 31);
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;

        .logout_btn {
            position: fixed;
            right: 0;
            top: 0;
            margin: 20px;

            button {
                text-align: center;
                background-color: #fff;
                color: #053112;
                padding: 10px;
                border-radius: 5px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #053413;
                    color: white;
                }

            }
        }

        .subState_inner {
            background-color: white;
            box-shadow: 0 0 38px rgba(0, 0, 0, 0.585);
            width: 50%;
            height: 50%;
            border-radius: 10px;
            padding: 30px 20px;
            position: relative;

            .texts {
                text-align: center;
                margin-top: 10px;

                p {
                    margin-top: 20px;
                }

                .subState_value {
                    background-color: rgb(255, 77, 77);
                    color: white;
                    padding: 10px;
                    width: 30%;
                    text-transform: capitalize;
                    display: flex;
                    justify-content: center;
                    margin: 0 auto;
                    text-align: center;
                    font-weight: 700;
                    border-radius: 5px;
                }
            }

            .btn-flex {
                display: flex;
                display: flex;
                justify-content: center;
                margin: 0 auto;
                position: absolute;
                bottom: 30px;
                width: 100%;
                left: 0;

                .button_cont {
                    margin-right: 20px;


                    button {
                        text-align: center;
                        background-color: #0b6223;
                        color: white;
                        padding: 10px;
                        border-radius: 5px;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            background-color: #053112;
                        }

                    }
                }
            }



        }
    }

    .controlPanel {
        padding: 0;
        // width: 95%;
        position: relative;

        .content {
            // overflow-y: hidden;
            background: #F4F6F8;
            // height: 100%;
            padding-bottom: 40px;
        }
    }

    .sidebar {
        // display: none;
    }
}