@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Poppins&display=swap");

.superUser {
  position: relative;
  .adminLogin {
    background-color: #0f362e;
    height: 100vh;
    clip-path: polygon(0 0, 100% 0, 100% 40%, 0 71%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loginCard {
    text-align: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 450px;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
      text-align: center;
      text-transform: none;
      font-size: 4rem;
      margin-block: 1rem;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      font-size: 1.2rem;
      text-align: start;

      button {
        background-color: #0f362e;
        color: white;
        font-weight: 900;
        border-radius: 7px;
        padding-block: 1rem;
        width: fit-content;
        align-self: center;
        padding-inline: 3rem;
      }
    }

    input {
      border: 2px solid black;
      border-radius: 5px;
      padding-inline: 3rem;
      padding-block: 1rem;
      width: 100%;

      ::placeholder {
        font-size: 1rem;
        color: black;
      }
    }
    .sign {
      display: flex;
      flex-direction: column;
      align-items: start;

      label {
        font-size: 1.8rem;
      }
    }
  }
}
