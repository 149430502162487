.subStat_pop_up {
    width: 100vw;
    // height: 100vh;
    position: fixed;
    top: -600px;
    left: 0;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;

    .red_dot{
        background-color: red;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.24);
        width: 30px;
        height: 30px;
        border-radius: 100px;
        position: absolute;
        left: -10px;
        top: -8px;
    }

    .subStat_pop_up_inner {
        background-color: rgb(130, 204, 222);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.29);
        max-width: 50%;
        padding: 20px;
        position: absolute;
        top: 20px;
        border-radius: 10px;

        @media only screen and (max-width: 680px) {
                max-width: 90%;
            }
    }
}

.subStat_pop_up.active {
    top: 10px;
    transition: all 0.4s ease-in-out;
}