.btn {
  display: block;
  // width: 100%;
  border-radius: 10px;
  font-size: 1.8rem;
  line-height: 1;
  font-weight: 300;
  text-transform: capitalize;
  color: white;
  background: #357a6b;
  padding: 1rem 10px;
  margin: 20px auto;
  border: 0;
  outline: none;
  cursor: pointer;
  // z-index: 1;
  transition: all .25s ease;

  @media only screen and (max-width: 680px) {
    font-size: 1.5rem;
  }
}



.btn:hover {
background: #52ab98;
}