.admin-home-container {
    display: block;
    margin-top: 20px;
    // height: 100%;

    body {
        background: none;
    }


    .column1 {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;

        .each-box {
            padding: 20px 40px;
            background-color: white;
            box-shadow: 0 8px 13px rgba(0, 0, 0, 0.047);
            text-align: center;
            border-radius: 5px;
            margin: 10px;
               
            a:hover{
                cursor: pointer;
            }
            @media only screen and (max-width: 680px) {
                            // padding: 0px;
                            width: 100%;
                            text-align: left;
                        }

            .box-head {
                color: rgb(150, 149, 149);
                
                @media only screen and (max-width: 680px) {
                        // padding: 0px;
                        text-align: left;
                    }
            }

            .box-content {
                color: rgb(53, 53, 53);
                font-size: 25px;
                font-weight: 600;
                margin-top: 10px;

                @media only screen and (max-width: 680px) {
                        // padding: 0px;
                        text-align: left;
                    }
            }
        }
    }

    .column2 {
        border-radius: 20px;
        display: block;
        padding: 20px;
        background-color: white;
        box-shadow: 0 8px 14px rgba(0, 0, 0, 0.116);
        margin: 20px 20px 0 20px;

        .head {
            display: flex;
            justify-content: space-between;
            width: 100%;

            @media only screen and (max-width: 680px) {
                   display: block;
                }

            p {
                font-weight: 900;
                font-size: 22px;

                @media only screen and (max-width: 680px) {
                                        font-size: 18px;
                                        text-align: left;
                    }
            }


            .filter_container{
                display: flex;

                .each-filt{
                    margin-right: 20px;

                    select{
                        border: 1px #134404 solid;
                        border-radius: 5px;
                        padding: 0 10px;
                    }
                }
            }
        }

        .chart {
            width: 90%;
            display: flex;
            justify-content: center;
            margin: 0 auto;
            padding: 0;

            @media only screen and (max-width: 680px) {
                    width: 100%;
                }
        }
    }
}