
.authWrapper {
  display: block;
  width: 100%;
  min-width: 50.0rem;
  min-height:100vh;
  max-height: 100vh;
  // height: 100%;
  // padding: 10px 10px;
  background: white;
  box-shadow: 0 3px 8px rgb(0 0 0 / 60%);
  // border-radius: 10px;
  overflow-y: scroll;
  position: relative;
  z-index: 0;
  left: 0;
  top: 0;

  .wrap {
    padding: 10px 20px;

    h2 {
      font-size: 2.2rem;
      line-height: 1;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0 auto 3rem;
      color: #389681;
    }

  }
}