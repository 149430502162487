.sidenav-container {
    width: 30rem;
    // height: 100%;
    position: relative;
    text-align: center;
    z-index: 25;

    .overlay{
        background-color: rgba(0, 0, 0, 0.523);
        width: 100vw;
        height: 100vh;
        position: fixed;
    }

    .nav-btn {
        position: relative;
        z-index: 22;

        @media only screen and (max-width: 680px) {
                        z-index: 10;
        
            }

        .nav-btn-cont {
            position: absolute;
            right: 15px;
            top: 20px;

            @media only screen and (max-width: 680px) {
                    left: 20px;
                    top: 15px;
                }

            .icon {
                background-color: #004937;
                // border: 1px white solid;
                padding: 5px;
                position: fixed;
                border-radius: 100px;
                cursor: pointer;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

    }

    .sidenav-inner-container {
        background-color: #004937;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.116);
        height: 100vh;
        top: 0;
        width: 25rem;
        z-index: 20;
        position: fixed;

        @media only screen and (max-width: 680px) {
                left: 0px;
        transition: all 0.2s ease-in-out;
            }


    }

    .userProfile {
        display: flex;
        align-items: center;
        width: 100%;
        // margin: 3rem auto 1rem;
        // background-color: white;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.116);
        margin: 0;
        // border-bottom: 1px solid #bcbcbc;

        padding: 10px;

        img {
            width: 50px;
            height: 50px;
            background-color: white;
            border-radius: 100px;
            padding: 2px;
            object-fit: cover;
        }

        .displayName {
            display: block;
            width: 100%;
            text-align: center;
            // margin: 1rem auto;
            font-size: 1.8rem;
            line-height: 1;
            text-transform: none;
            padding: 8px;

            .bus-name {
                overflow: hidden;
                white-space: nowrap;
                display: flex;
                padding: 2px;
                color: white;
                font-size: 18px;

                &:hover {
                    color: black;
                }
            }

            .bus-id {
                overflow: hidden;
                white-space: nowrap;
                display: flex;
                padding: 2px;


                span {
                    font-size: 12px;
                    color: rgb(58, 58, 58);
                    padding: 2px;

                    &:hover {
                        color: rgb(58, 58, 58);
                    }
                }
            }
        }
    }

    .menu-list {
        // height: 100%;
        // position: relative;

        .header {
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 14px;
            margin-left: 10px;
            text-align: center;
            width: 100%;
            color: #afafaf;
            letter-spacing: normal;
            line-height: 1.23;
        }

        ul,
        li {
            margin: 0;
            padding: 0;
            width: 100%;
            list-style-type: none;
        }

        ul {

            li {

                // border-bottom: 1px solid #d3d3d3;

                &:first-child {
                    // border-top: 1px solid #d3d3d3;
                }

                .overviewlink {
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    border-bottom-right-radius: 20px;
                    border-top-right-radius: 20px;

                    .fa {
                        align-items: center;
                        //  margin-top: 5px;
                        color: #fff;
                        font-size: 35px;
                    }

                    span {
                        color: #fff;
                        font-weight: 600;
                        font-size: 15px;
                        padding: 10px;
                    }

                    &:hover {
                        color: #ffffff;
                        background-color: #00644b;
                        border-left: 5px solid #00110d;


                        span {
                            color: #ffffff;
                        }

                        .fa {
                            color: #ffffff;
                        }
                    }
                }

                .overviewlink.active {
                    color: #ffffff;
                    background-color: #00644b;
                    border-left: 5px solid #00110d;


                    span {
                        color: #ffffff;
                    }

                    .fa {
                        color: #ffffff;
                    }
                }

                .companylink {
                    margin: 2px 0;
                    display: flex;
                    width: 100%;
                    padding: 5px 15px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1;
                    color: rgb(222, 222, 222);
                    align-items: center;
                    cursor: pointer;
                    border-bottom-right-radius: 20px;
                    border-top-right-radius: 20px;

                    span {
                        padding: 1rem;
                    }

                    .fa {
                        align-items: center;
                        //  margin-top: 5px;
                        font-size: 20px;
                    }

                    &:hover {
                        color: #ffffff;
                        background-color: #00644b;
                        border-left: 5px solid #00110d;
                    }
                }

                .companylink.active {
                    color: #ffffff;
                    background-color: #00644b;
                    border-left: 5px solid #00110d;
                }

                .upgrade-container {
                    // margin: 2px 0;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    font-size: 18px;
                    color: #029752;
                    align-items: center;
                    background-color: #fff;
                    border-radius: 5px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.39);


                    .remove-padding {
                        padding: 10px 5px;
                    }

                    .red {
                        color: red;
                        font-weight: 700;
                        padding: 10px 0;
                    }

                    span {
                        padding: 1rem;
                    }

                    .upgradeBtn {
                        background-color: #029752;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        // padding-right: 60px;
                        color: white;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        cursor: pointer;

                        @media only screen and (max-width: 680px) {
                                font-size: 15px;
                            }

                        &:hover {
                            background-color: #00502b;
                        }
                    }
                }
            }

            .companylink-submenu {
                opacity: 1;
                height: 100%;

                .li1 {
                    font-size: 14px;
                    padding: 8px 10px 8px 20px;
                    margin: 2px 0;
                    display: block;
                    justify-content: center;
                    cursor: pointer;

                    a {
                        color: rgb(199, 199, 199);
                    }

                    &:hover {
                        a {
                            color: #ffffff;
                        }

                        // border-left: 2px solid #00644b;
                    }
                }

                .li1.active {
                    a {
                        color: #ffffff;
                    }

                }
            }

            .companylink-submenu.active {
                // opacity: 0;
                // height: 0;
                display: none;
                transition: all .4s ease-in-out;

                @media only screen and (max-width: 680px) {
                        display: block;
                    }
            }

        }
    }
}

.sidenav-container.active {
    width: 7rem;
    @media only screen and (max-width: 680px) {
            position: absolute;
            left: 0;
        }

    .sidenav-inner-container {
        width: 7rem;
        transition: all 0.2s ease-in-out;

        @media only screen and (max-width: 680px) {
                    width: 25rem;
                    left: -280px;
            }

        .menu-list {
            text-align: center;
            @media only screen and (max-width: 680px) {
                            text-align: left;
                }

            .displayName {
                display: none;

                @media only screen and (max-width: 680px) {
                        display: block;
                    }
            }

            .overviewlink {
                justify-content: center;
                padding: 10px 0;

                @media only screen and (max-width: 680px) {
                                    justify-content: left;
                                    align-items: center;
                padding: 10px;

                    }
            }

            .overviewlink span {
                display: none;

                @media only screen and (max-width: 680px) {
                                        display: block;
                    }
            }

            .companylink {
                padding: 10px 0;
                justify-content: center;

                @media only screen and (max-width: 680px) {
                        justify-content: left;
                        align-items: center;
                        padding: 5px 15px;
                
                    }
            }

            .companylink span {
                display: none;

                @media only screen and (max-width: 680px) {
                        display: block;
                    }
            }

            .upgrade-container {
                display: none;
                width: 100%;
                // height: 10rem;
                background-color: transparent;
                

                div {
                    // display: none;
                    margin-bottom: 20px;
                    background-color: rgb(255, 255, 255);
                    width: 100%;
                    margin-bottom: 10px;
                    padding: 0 5px;
                    border-radius: 0px;

                    span {
                        padding: 0;
                        margin: 0;
                    }
                }

                .upgradeBtn {
                    // width: 100%;
                    margin: 0;
                    padding: 5px 0;
                    border-radius: 5px;

                    span {
                        margin: 0;
                        padding: 0;
                    }
                }

            }
        }
    }
}