.pricing {
  .pricing_block {
    background: 
            linear-gradient(to right, white, #b6ffe119, #b6ffe119, white, #b6ffe119, #b6ffe125, white),
    linear-gradient(to bottom, white, #b6ffe157 10%, white);

    // background: linear-gradient(to right, transparent, rgba(83, 141, 118, 0.437), transparent);
    backdrop-filter: blur(10px);
    width: 100%;
    margin-inline: auto;
    padding: 10rem 0;
  }

  h1 {
    width: 35%;
    margin-inline: auto;
    line-height: 40px;
    text-align: center;
    text-transform: none;
    font-weight: 700;
    font-size: 4.5rem;
  }

  .pricing_text {
    margin-top: 2rem;
  }

  .pricing_price {
    margin-block: 5rem;

    .options {
      border: 2px solid black;
      border-radius: 30px;
      padding: 15px 8px;
      width: fit-content;
      margin-inline: auto;
      font-size: 1.5rem;

      .monthly {
        color: white;
        background-color: black;
        border: 2px solid black;
        border-radius: 30px;
        padding: 0.6rem;
        margin: 0;
      }

      span {
        margin-inline: 1rem;
        cursor: pointer;
      }
    }
  }

  .price_options {
    margin-block: 5rem;
    display: flex;
    align-items: center;
    gap: 4rem;
    justify-content: center;
  }

  .basic {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.182);
    padding: 2.5rem;
    // min-height: 45rem;
    min-width: 25rem;

    h2 {
      font-weight: 700;
      text-transform: none;
      margin-block: 2rem;
      text-align: center;
    }

    .basic_text {
      margin-top: 2rem;
    }

    p {
      font-size: 1rem;
    }

    .main_price {
      font-weight: 600;
      margin-block: 2rem;

      span {
        font-size: 4rem;
      }
    }

    .benefits_start {
      text-align: start;
      margin-bottom: 1rem;
    }

    .benefits {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-block: 1rem;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .not {
      color: rgb(192, 192, 192);
    }

    button {
      background-color: black;
      border-radius: 25px;
      padding-inline: 4rem;
      padding-block: 0.8rem;
      margin-top: 1rem;
      font-weight: normal;
      font-size: 1.2rem;
      color: white;
    }
  }

  .none {
    display: none;
  }

  .hardware {
    margin-block: 10rem;

    h2 {
      text-align: center;
      font-weight: 700;
      text-transform: none;
    }

    .hardware_plans {
      display: flex;
      gap: 3rem;
      margin-block: 3rem;
      justify-content: center;
    }
  }

  .cta {
    margin-block: 15rem;
        background:
            linear-gradient(to right, white, #b6ffe119, #b6ffe119, white, #b6ffe119, #b6ffe125, white),
            linear-gradient(to bottom, white, #b6ffe157 10%, white);
        
          // background: linear-gradient(to right, transparent, rgba(83, 141, 118, 0.437), transparent);
          backdrop-filter: blur(10px);
          padding: 5rem 0;
    // width: 50%;
    

    h2 {
      text-transform: none;
      font-weight: 700;
      width: 60%;
      margin-inline: auto;
      text-align: center;
      font-size: 60px;
      line-height: 60px;
      margin-block: 2rem;
    }

    p {
      margin-block: 2rem;
    }
  }
  .free {
    border: 2px solid grey;
    font-size: 1.5rem;
    width: fit-content;
    margin-inline: auto;
    border-radius: 20px;
    padding-inline: 6rem;
    padding-block: 0.5rem;
    color: white;
    background-color: #054a49;
    font-weight: 600;
  }
}

@media screen and (max-width: 767px) {
  .pricing {
    h1 {
      width: 100%;
    }

        .cta h2 {
          font-size: 38px;
          line-height: 34px;
        }

    .price_options {
      flex-direction: column;
    }

    .basic {
      width: 80%;
    }

    .hardware {
      .hardware_plans {
        flex-direction: column;
        align-items: center;
      }
    }

    .cta {
      h2 {
        width: 100%;
      }
    }
  }
}
