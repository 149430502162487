 .dialogue-loading {
     position: fixed;
     left: 0;
     top: 0;
     background-color: rgba(0, 0, 0, 0.778);
     width: 100vw;
     height: 100vh;
     z-index: 20;
     display: flex;
     justify-content: center;
     align-items: center;

     .player {}
 }