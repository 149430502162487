.calculator {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #178c71;

  .display {
      font-size: 24px;
      margin-bottom: 10px;
      padding: 10px;
      background-color: #f0f0f0;
      text-align: right;
    }
  
    .keypad {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
    }
  
    .keypad button {
      color: black;
      font-size: 20px;
      padding: 10px;
      text-align: center;
      background-color: #e4d8d8;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
    }
  
    .keypad button:hover {
      background-color: #dad6d6;
    }
  
    .keypad .equal {
      color: #fff;
      background-color: rgba(6, 85, 48);
      grid-column: span 2;
    }
  
    .keypad .equal:hover {
      background-color: rgba(29, 175, 149);
    }
  
    .keypad .signs {
      background-color: rgba(29, 175, 149);
      color: white;
    }
  
    .keypad .clear {
      grid-column: span 2;
      background-color: #fcbd24;
    }
}

