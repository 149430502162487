.calculator-cont {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    // z-index: 100;

    .cal-overlay {
        background-color: rgba(0, 0, 0, 0.319);
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 1;
    }

    .cal-cont{
        position: relative;
        display: flex;
        flex-direction: column;
        // justify-content: right;
        align-items: end;

        .closeBtn{
            z-index: 14;
                position: relative;
            background-color: white;
            padding: 2px;
            border-radius: 100px;
            cursor: pointer;
            right: 0;
            margin-bottom: -10px;
            margin-right: -10px;
        }
    }

    .calculator {
        z-index: 10;
        position: relative;
    }

}

.Admin-nav {
    display: flex;
    justify-content: right;
    background-color: white;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.144);
    border-radius: 2px;
    align-items: center;
    // margin-bottom: 5px;
    // margin-right: 5px;
    // margin-left: 5px;
    padding: 0 20px 0 0;
    position: sticky;
    top: 0;
    z-index: 10;
    position: -webkit-sticky;
    width: 100%;



    .float-right {
        float: right !important;
        display: flex;
        align-items: center;

        .menu-icon {
            .menu-ul {
                padding: 10px;
                margin: 0;

                li {
                    list-style: none;
                    padding: 10px;
                    height: 50px;
                    width: 50px;
                    border-radius: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        background-color: #009c75a8;
                        color: white;

                        i {
                            color: white;
                        }
                    }
                }
            }

            .li.active {
                background-color: #00644b1e;
            }

            .hidden-nav {
                display: contents;
                background-color: white;
                position: absolute;
                right: 20px;
                box-shadow: 0 3px 8px rgba(0, 0, 0, 0.247);
                padding: 3px;
                display: block;
                border-radius: 5px;
                margin: 10px 0 0 0;
                font-size: 15px;
                cursor: pointer;

                li {
                    list-style: none;
                    padding: 20px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 680px) {
                            padding: 10px;

                            span{
                                font-size: 18px;
                            }
                        }

                    &:hover {
                        background-color: #009c75;
                        color: white;
                    }

                    i {
                        padding-right: 10px;
                        font-size: 20px;
                    }
                }


            }

            .hidden-nav.active {
                display: none;
            }
        }

        .notification-btn {
            &:hover {
                transform: scale(1.1);
            }

            padding: 10px;
            transition: transform .2s;

            ul {
                margin: 0;

                li {
                    list-style: none;

                    @media only screen and (max-width: 680px) {
                        padding: 0px;
                    }

                    .fa {
                        font-size: 25px;
                        color: #009c75;
                    }

                    .red-dot {
                        // display: none;
                        width: 15px;
                        height: 15px;
                        background-color: red;
                        border: 1px solid white;
                        border-radius: 100%;
                        position: absolute;
                        margin-top: -17px;
                        margin-left: 12px;
                    }
                }
            }
        }

        .account-btn {
            padding-left: 10px;
            align-items: center;
            display: block;
            justify-content: center;

            .acct-ul {
                padding: 5px;
                margin: 0;
                border-radius: 100%;
                transition: all .2s ease-in-out;

                &:hover {
                    background-color: #00644b4d;
                }

                li {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    border: 2px solid #757575;
                    ;
                    border-radius: 100%;
                    padding: 10px;
                    width: 35px;
                    height: 35px;
                    justify-content: center;
                    font-size: 30px;

                    .fa {
                        color: #757575;
                        font-size: 20px;
                    }

                }
            }

            .acct-ul.active {
                background-color: #00644b1e;
            }

            .hidden-nav {
                display: contents;
                background-color: white;
                border-radius: 5px;
                position: absolute;
                right: 20px;
                box-shadow: 0 3px 8px rgba(0, 0, 0, 0.144);
                padding: 0;
                display: block;
                margin: 10px 0 0 0;
                cursor: pointer;

                .sigoutlink {
                    border-top: 1px solid rgb(182, 182, 182);
                }

                li {
                    list-style: none;
                    font-size: 14px;
                    padding: 0;
                    width: 100%;
                    transition: all .2s ease-in-out;

                    a {
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        padding: 15px 20px;

                    }

                    .fa-power-off {
                        color: red;
                    }

                    .fa-phone {
                        color: rgb(138, 0, 192);
                    }

                    .fa-user {
                        color: rgb(74, 74, 255);
                    }

                    span {
                        width: 100%;
                        text-align: left;
                        padding-left: 10px;

                        @media only screen and (max-width: 680px) {
                            font-size: 16px;
                        }
                    }

                    &:hover {
                        background-color: #009c75;
                        color: white;

                        i {
                            color: white;
                        }
                    }
                }
            }

            .hidden-nav.active {
                display: none;

            }


        }
    }




}