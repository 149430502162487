@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Poppins&display=swap');

.signupFlex {
display: flex;
  justify-content: center;
  background-color: #0f362e;
  height: 100vh;

  .right-cont {
    width: 60%;
      height: 100%;
      padding: 3rem;
      float: right;


      @media only screen and (max-width: 680px) {
          display: none;
        }
    
      .logo {
        display: flex;
        align-items: center;
    
        img {
          width: 17%;
        }
    
        h1 {
          color: white;
          font-weight: 900;
          margin-left: 20px;
        }
      }
    
      // .left-center {
      //   display: block;
      //   justify-content: center;
      //   align-items: center;width: 100%;
      //   height: 50%;
      // }

  }
}

.right-formWrap {
  float: left;
  font-family:'Poppins', sans-serif;
  margin: 0px auto ;
  display: block;
  height: 100%;
  width: 40%;
  background-color: #52ab98;
  padding: 20px;
  overflow-y: scroll;

        .back_btn {
            position: absolute;
            top: 0;
            left: 0;
            padding: 10px;
        
            a {
              cursor: pointer;
        
              svg {
                transition: all 0.2s ease-in-out;
        
                &:hover {
                  width: 35px;
                  height: 35px;
                }
              }
            }
          }

  @media only screen and (max-width: 680px) {
        width: 100%;
    }

  .nextbtn{
    text-transform: uppercase;
    font-family:'Poppins', sans-serif;
    font-weight: 600;
  }

  .progress-cont{
    display: block;
    width: 100%;
    padding-bottom: 8px;
    margin: 0px;

    .stage-cont{
      display: flex;
      justify-content: space-evenly;
      background-color: transparent;
      color: white;
      align-items: center;

      .checker.active{
        display: none;
        opacity: 0;
      }
      
      .checker{
        opacity: 1;
        

        .checker-icon{
          width: 36px;
          height: 36px;
        }
      }

      .checker2.active{
        display: none;
        opacity: 0;
      }
      
      .checker2{
        opacity: 1;

        .checker-icon{
          width: 36px;
          height: 36px;
        }
      }

      .checker3.active{
        display: none;
        opacity: 0;
      }
      
      .checker3{
        opacity: 1;

        .checker-icon{
          width: 36px;
          height: 36px;
        }
      }

      

      .stage1{
        background-color: #338170;
        border-radius: 500px;
        display: inline-block;
        font-weight: 600;
        text-align: center;
        height: 36px;
        align-items: center;
        width: 36px;
        line-height: 30px;
        box-shadow: 0 2px 6px -2px rgb(0 0 0/16%);
        text-transform: uppercase;
    padding: 3px 5px;
    margin-top: 1px;
      }

      .stage1.active{
        display: none;
      }

      .stage2{
        background-color: #338170;
        border-radius: 500px;
        display: inline-block;
        font-weight: 600;
        text-align: center;
        height: 36px;
        align-items: center;
        width: 36px;
        line-height: 30px;
        box-shadow: 0 2px 6px -2px rgb(0 0 0/16%);
        text-transform: uppercase;
    padding: 3px 5px;
    margin-top: 1px;
      }

      .stage2.active{
        display: none;
      }

      .stage3{
        background-color: #338170;
        border-radius: 500px;
        display: inline-block;
        font-weight: 600;
        text-align: center;
        height: 36px;
        align-items: center;
        width: 36px;
        line-height: 30px;
        box-shadow: 0 2px 6px -2px rgb(0 0 0/16%);
        text-transform: uppercase;
    padding: 3px 5px;
    margin-top: 1px;
      }

      .stage3.active{
        display: none;
      }
    }
  }

  .err{
    list-style: none;
  }
  
  .theLogo-container{
    width: 70%;
    height: 50px;
    padding-left: 40px;
    display: flex;
    justify-content: left;
    padding-bottom: 20px;
    padding-left: 20px;
    @media only screen and (max-width: 680px) {
          width: 100%;
      }
    .theLogo{
        display: flex;
        align-items: center;
                  justify-content: center;

        img{
            width: 20%;
            margin: 10px;

            
        }

                @media only screen and (max-width: 680px) {
                img{
                  width: 50%;
                  margin: 0 auto;
                }
                }
    }
}

  .txt{
    margin: 0px 0px 0px 20px;
    padding-bottom: 0px;
    // width: 100%;
    text-align: center;
    font-family:'Poppins', sans-serif;

    @media only screen and (max-width: 680px) {
        margin: 0;
      }

    .head{
      font-size: 30px;
      position: relative;
      font-weight: 600;
      width: 100%;
      color: white;
    }

    .cont{
      text-align: center;
      margin: 14px 0 20px 0;
      color: rgb(230, 230, 230);
      line-height: 1.6;
      font-size: 16px;

      span{
        width: 60%;
      }
    }
  }

  .input-heading{
    margin-top: 20px;
    // margin-bottom: 20px;
    padding: 10px 10px 0 0px;
    font-weight: 600;
  }
  .stage1Content{
    display: flex;
    justify-content: center;
  }
    
    .stage1Content-inner {
      display: block;
    }

  .stage1Content.active{
    display: none;
  }

  .stage2Content{
    display: none;
  }

  .stage2Content.active{
    display: contents;
  }

  .stage3Content{
    display: none;
    height: 1000px;

    
  }

  .stage3Content.active{
    display: contents;

    .loginbutton{
      margin: 60px;
      padding: 20px;
    }

    a{
      text-decoration: none;
      color: white;
    }

    .head{
      width: 100%;
      text-align: center;
      margin: 20px 0;
      font-weight: 700;
      font-size: 20px;
    }

    .alert-info {
      border-color: #338170;
      background: #c1fcef;
      color: #24695a;
  }
  
  .alert {
      border-left: 5px solid;
      border-top: none;
      border-bottom: none;
      border-right: none;
      // border: 1px solid transparent;
      border-radius: 4px;
      padding: 15px;
      margin-bottom: 20px;
  }
  }
  
  .each-input {
    display: block;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    margin: 10px auto;
    padding: 0 10px;
    
    // padding-left: 12px;
    // padding: 10px 5px;
    // margin: 5px 5px  10px auto;

    .passerr{
      color: #f05050;
      font-size: 12px;
      margin-top: 10px;
      font-weight: 500;
    }

    .passerr.active{
      display: none;
    }

    .form-flex-cont{
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        display: block;
        .form-flex{
          margin-top: 8px;
        }
      }
    }
    
    .form-flex{
      border: 1.2px solid #235e51;
    border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      margin-top: 5px;
      width: 100%;
      margin-right: 10px;

      select{
        border: none;
        padding: 12px;
        font-size: 16px;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 100%;
        background-color: transparent;
        background: transparent;

        option{
          margin: 10px 0;
        }
      }

      select::-ms-expand{
        display: none;
        border: none;
      }

      span{
        padding: 12px;
        font-weight: 700;
        color: #235e51;
      }

      .visibility-btn{
        width: 43px;
        height: 44px;
        background: #3381705d;
        align-items: center;
        display: flex;
        justify-content: center;
        border: none;

        .toggle-Icn{
          width: 100%;
          display: flex;
          justify-content: center;

          .fa1{
            display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    width: 14px;
    height: 14px;
    opacity: 0.7;
          }

          .fa1.active{
            display: none;
          }
        }
      }
    }
  }

  .checkbox-container{
    margin-top: 10px;
    padding: 0px 10px;

    .container{
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 0px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      p{
        font-size: 15px;
        font-weight: 500;

      }
    }

    .coloring{
      color: red;
    }
  }

  .links {
    color: rgb(29, 29, 29);

    a {
      text-decoration: none;
      font-size: 15px;
      color: rgb(29, 29, 29);
      font-weight: 600;
      transition: all .35s ease;

      &:hover {
        color: #338170;
        font-weight: 700;
      }
    }
  }

  .flex{
    justify-content: center;
    text-align: right ;
    display: flex;
    padding: 0px 20px 0px 20px;
    
    li{
      font-weight: 400;
      font-size: 15px;
      list-style: none;
    }
  }

  .btm-hint{
    padding-left: 20px;
    color: rgb(92, 92, 92);
    
    h5{
      font-weight: 400;
      font-size: 20px;
    }

    .list-benefits{
      li{
        margin-top: 10px;

        svg{
          margin-right: 10px;
          path{
            fill: rgb(91, 179, 0);
          }
        }
      }
    }
  }

    @media only screen and (max-width: 680px) {
      a{
        font-size: 15px;
      }
    }
}

.err {
  li {
    list-style: none;
    color: rgb(255, 37, 37);
  }
  
}