.formRow {
  // padding: 6px 10px 0 10px;
  // border: 1.2px solid #235e51;
  //   border-radius: 5px;
    padding-left: 12px;
    padding: 5px 5px;
    // margin: 5px 5px  10px auto;
  width: 100%;
  background: transparent;

  input,
  &.checkoutInput select {
    display: block;
    width: 100%;
    font-size: 2rem;
    line-height: 1;
    font-weight: 400;
    text-align: left;
    background: transparent;
    // padding: 10px 5px;
    // margin: 0px 5px  10px auto;
    outline: none;
    border: none;
    // border-bottom: 2px solid #b8b8b8;
    // border: 1.2px solid #235e51;
    // border-radius: 5px;
    // padding-left: 12px;
  }
}