.header-container {
    background: #dbdfdb;
    // min-height: 100vh;
    width: 100%;
    color: #006400;
    position: relative;

    header {
        background-color: #006400;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 40px;
        // height: 20%;

        .logo {
            width: 60px;
            margin-right: 20px;
            cursor: pointer;
        }

        nav {
            display: flex;
            align-items: center;
            padding: 10px 8px;

            ul {
                flex: 1;
                text-align: right;

                li {
                    font-size: 18px;
                    display: inline-block;
                    list-style: none;
                    margin: 0 20px;
                    transition: all 0.2s ease-in-out;

                    a {
                        text-decoration: none;
                        color: rgb(255, 255, 255);
                    }

                    &:hover{
                    transform: scale(1.1);

                    }
                }
            }
        }
    }

    button {
        background: rgb(242, 242, 242);
        height: 30px;
        width: 60px;
        border-radius: 20px;
        border: 0;
        outline: 0;
        cursor: pointer;
        transition: background 0.5s;
        margin: 0;
    
        &.tog-btn {
            margin-right: 5px;
    
            span {
                display: block;
                background: #999;
                height: 26px;
                width: 26px;
                border-radius: 50%;
                margin-left: 2px;
                transition: background 0.5s, margin-left 0.5s;
            }
    
            &.active {
                background: #197922;
    
                span {
                    background: #fff;
                    margin-left: 30px;
                }
            }
        }
    }
}


