footer {
    display: block;
    padding: 5rem;

    .grid {
        display: flex;
        flex-wrap: wrap;
        padding: 5rem;
        justify-content: space-between;

        .text1 {

            .logo {
                margin-left: 10px;

                img {
                    width: 50px;
                    height: 50px;
                }
            }

            .online p {
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                color: #414141;
            }

            .flex-socials {
                display: flex;
                align-items: center;
                padding: 15px 10px;

                .socials a img {
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                    margin-right: 20px;
                    transition: all 0.2s ease-in-out;

                    &:hover{
                       transform: scale(1.2);
                    }
                }
            }
        }


        .grid2 {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            div ul {
                padding-left: 4rem;
                margin-left: 30px;



                li {
                    text-align: left;

                    a {
                        font-weight: 400;
                        color: #414141;
                        font-size: 16px;

                        &:hover{
                        color: #1c9860;
                        }
                    }
                }

                .bold {
                    font-weight: 700;
                        font-size: 20px;
                        color: #6D6969;
                }
            }

                        
        }
    }

    .copyright {
        font-size: 14px;
        font-weight: 400;
    }
}

@media (max-width: 714px) {
    footer {
        padding: 1rem;
    
        .grid {
            display: flex;
            flex-wrap: wrap;
            padding: 2rem;
            justify-content: space-between;
    
            .text1 {
    
                .logo {
                    margin: 0;
                    display: flex;
                    justify-content: center;
    
                    img {
                        width: 50px;
                        height: 50px;
                        margin: 0;
                    }
                }

                .online{
                    padding-top: 20px;
                }
    
                .online p {
                    text-align: left;
                    font-size: 16px;
                    font-weight: 400;
                    color: #414141;
                }
    
                .flex-socials {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 15px 10px;
    
                    .socials a img {
                        width: 20px;
                        height: 20px;
                        object-fit: contain;
                        margin-right: 20px;
                        transition: all 0.2s ease-in-out;
    
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }
    
    
            .grid2 {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
    
                div ul {
                    padding: 0 1rem;
                    margin: 0 10px;
                    // width: 100vw;
    
    
    
                    li {
                        text-align: center;
                        padding: 0;
                        line-height: 20px;


    
                        a {
                            font-weight: 400;
                            color: #414141;
                            font-size: 16px;
                            // text-align: center;
    
                            &:hover {
                                color: #1c9860;
                            }
                        }
                    }
    
                    .bold {
                        font-weight: 700;
                        font-size: 20px;
                        color: #6D6969;
                        padding: 0;
                        line-height: 15px;
                        margin-top: 30px;
                        margin-bottom: 10px;

                    }
                }
    
    
            }
        }
    
        .copyright {
            font-size: 14px;
            font-weight: 400;
        }

    }
}





// @media (min-width:415px) and (max-width: 680px) {

//     footer {
//         margin: 50%;
//         margin-top: 0;
//         color: #6D6969;

//         .grid {
//             grid-template-columns: repeat(1, 2%);

//             margin-left: -5em;
//             text-align: center;
//             border-bottom: none;

//         }

//         .grid2 {
//             grid-template-columns: repeat(1, 100%);
//             margin-top: -250px;
//             gap: 20px;
//             text-align: center;
//             width: 1000vh;
//             margin-left: -1em;
//         }

//         .logo {
//             margin-left: -1px;
//         }

//         .logo img {
//             width: 90px;
//             height: 40px;
//         }

//         .online {
//             width: 100vh;
//             margin-left: -5rem;
//             font-size: 15px;
//         }

//         a {
//             color: #6D6969;
//             font-size: 15px;
//             text-align: center;
//         }

//         .bold {
//             color: #6D6969;
//             font-size: 25px;
//         }

//         .seam {
//             margin: 0;
//         }

//         .copyright {
//             width: 100vh;
//             text-align: center;
//             color: #6D6969;
//             margin-left: -20rem;
//             border-top: 1px solid #6D6969;
//         }
//     }


// }