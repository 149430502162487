.products-container {
    display: block;
    padding: 10px 3rem;

    @media only screen and (max-width: 680px) {
            padding: 0px;
        }

    .page-url-cont {
        display: block;
        padding: 10px;

        .page-url {
            display: flex;
            align-items: center;

            span {
                font-size: 15px;
                font-weight: 500;
                padding: 0 5px 0 0;
            }

            .slash {
                color: rgb(204, 204, 204);
            }

            .colored {
                color: #00d890;
                cursor: pointer;
            }

            .mainurl {
                color: #000000;
            }
        }
    }

    .column1 {
        display: flex;
        width: 100%;
        justify-content: right;
        flex-direction: row-reverse;
        align-items: center;

        @media (max-width: 768px) {
                justify-content: center;
                display: flex;
                flex-direction: column-reverse;
            }

        .each-box {
            padding: 20px 40px;
            background-color: rgb(6, 112, 57);
            box-shadow: 0 8px 13px rgba(0, 0, 0, 0.116);
            text-align: center;
            border-radius: 10px;
            margin-right: 15px;
            

            @media (max-width: 768px) {
                margin-top: 15px;
                width: 80%;
            }

            .box-head {
                color: rgb(225, 225, 225);
            }

            .box-content {
                color: rgb(255, 255, 255);
                font-size: 25px;
                font-weight: 600;
                margin-top: 10px;
            }
        }
    }

    .preview-container {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        

        .dark-overlay {
            background-color: rgba(0, 0, 0, 0.219);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
        }

        .preview-inner-container {
            background-color: white;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.24);
            // margin: 20px 0px 10px 0px;
            padding: 20px;
            max-width: 70%;
            border-radius: 5px;
            display: block;
            position: relative;
            z-index: 1;
            // top: 30%;
            overflow: scroll;

            @media only screen and (max-width: 680px) {
                    max-width: 100%;
                    width: 100%;
                    padding: 20px;
                    height: 100vh;
                }

            .head{
                display: flex;
                justify-content: center;

                p{
                border-bottom: 3px solid #025830;
                // width: 50px;
                }
            }

            .closeBtn {
                display: flex;
                justify-content: right;
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 10px;

                @media only screen and (max-width: 680px) {
                    svg{
                    width: 30px;
                    height: 30px;
                    }
                }
            }

            ul li {
                margin-top: 10px;
                width: 100%;
                text-align: left;

                @media only screen and (max-width: 680px) {
                    text-align: center;
                    padding: 0;
                    margin: 0;
                        span{
                            font-size: 15px;
                        }
                    }

                .flex{
                    padding: 20px 50px;
                    align-items: center;

                    @media only screen and (max-width: 680px) {
                            display: block;
                            padding: 0;

                            li{
                            font-size: 17px;
                            }
                    }
                }

                .qrCode{
                    border-radius: 10px;
                    background-color: white;
                    padding: 15px;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.24);
            margin-left: 20px;

            @media only screen and (max-width: 680px) {
                    background-color: transparent;
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    // padding: 0;
                    align-items: center;
                    width: 50%;
                    margin: 0 auto;
                }
                }
            }

            .edit_form {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-wrap: wrap;
                align-items: center;

                .each-input {
                    width: 80%;
                    

                    .input-heading {
                        font-size: 16px;
                        margin-left: 20px;
                    }

                    input {
                        // background-color: rgb(204, 204, 204);
                        border: 1px black solid;
                        border-radius: 10px;
                        padding: 5px;
                    }
                }
            }

            button {
                text-align: center;
                background-color: #007558;
                margin: 0 auto;
                display: flex;
                color: white;
                padding: 5px 10px;
                border-radius: 5px;

                @media only screen and (max-width: 680px) {
                        padding: 7px;
                        font-size: 17px;
                    }
            }
        }

    }

    .pos-sales-content-container {
        background-color: white;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.24);
        overflow-x: hidden;
        margin: 20px 0px 10px 0px;
        padding: 20px 0 20px 0;
        width: 100%;
        // max-height: 60rem;
        border-radius: 5px;
        display: block;
        // height: 400px;
        min-height: 400px;

        .tab-controls {


            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 20px;
@media only screen and (max-width: 680px) {
        padding: 0px;
    }


                li {
                    list-style: none;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 680px) {
                            padding: 10px;
                        }

                    .add-icn {
                        margin: 0 10px;
                        background-color: #007558b5;
                        backdrop-filter: blur(3px);
                        border-radius: 100px;
                        padding: 5px;
                        cursor: pointer;
                        position: fixed;
                        right: 0;

                        &:hover {
                            background-color: #007558;
                        }

                                                @media only screen and (max-width: 680px) {
                                                    top: 30%;
                                                }
                    }

                    .ref-icn{
                        margin: 0 10px;
                            background-color: #007558;
                            border-radius: 100px;
                            padding: 5px;
                            cursor: pointer;
                        
                            &:hover {
                                background-color: #014332;
                            }
                    }

                    input {
                        // border: 1px solid black;
                        margin: 0 10px;
                        box-shadow: 0 0px 4px rgba(0, 0, 0, 0.24);
                        border-radius: 5px;
                        padding: 5px;
                        font-size: 15px;

@media only screen and (max-width: 680px) {
        margin: 0px;
        width: 153px;
    }
                        
                    }

                    span {
                        font-size: 14px;
                        font-weight: 600;
                        color: #686868;
                        text-transform: uppercase;
                        padding-bottom: 6px;
                        transition: all .4s ease-in-out;
                        cursor: pointer;

                        &:hover {
                            color: #00bb8c;
                        }
                    }

                    .tab1.active {
                        border-bottom: 4px solid #007558;
                        border-bottom-left-radius: 2px;
                        border-bottom-right-radius: 2px;
                        color: #00bb8c;

                        @media only screen and (max-width: 680px) {
                                // padding-left: 5px;
                            }
                    }
                }
            }
        }

        .tab-content {
            color: #777;
            padding: 20px !important;

            @media only screen and (max-width: 680px) {
                    padding: 0px !important;
                    margin-top: 10px;
                }

            .tab-pane.active {
                display: none;
            }

            .tab-pane {
                display: block;

                .table-responsive {
                    display: block;
                    width: 100%;
                    overflow-x: auto;
                    border: 0 solid #ebeff2;
                    overflow: auto;

                    table {
                        border-spacing: 0;
                        border-collapse: collapse;

                        tbody tr .text-center {
                            text-align: center;

                            .center {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                }

                            .font-bold {
                                font-weight: 600;
                            }

                        }

                        tbody tr {
                            border-bottom: 1px rgb(158, 158, 158) solid;
                            margin: 20px 0;
                            font-size: 15px;
                        }

                        .action-btn {
                            color: white;
                            background-color: rgb(19, 128, 55);
                            border-radius: 10px;
                            // width: 40%;
                            margin: 0 auto;
                            cursor: pointer;
                            font-size: 15px;
                            padding: 10px 0;

                            &:hover {
                                background-color: red;
                            }
                        }
                    }

                    .table {
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 20px;

                        .thead-light th {
                            color: #6c757d;
                            background-color: #f8f6ff;
                            border-color: #dee2e6;
                            white-space: nowrap;
                            padding: 8px;
                            line-height: 1.42857143;
                            border: 0 !important;
                            font-size: 15px;
                        }
                    }

                    .action-btn {
                        color: white;
                        background-color: rgb(19, 128, 55);
                        border-radius: 10px;
                        // width: 40%;
                        margin: 0 auto;
                        cursor: pointer;
                        font-size: 15px;
                        padding: 10px 0;

                        &:hover {
                            background-color: red;
                        }
                    }
                }
            }

                        
        }
    }
}