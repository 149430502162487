.superUserDashboard {
  padding: 5rem;
  background-color: #f7f7f7;
  min-height: 100vh;

  .heading {
    text-align: start;
    width: fit-content;

    h1 {
      font-size: 2rem;
      text-transform: none;
      font-size: 700;
    }
    p {
      text-align: start;
      font-size: 1.5rem;
      color: rgb(223, 219, 219);
    }
  }

  .userContainer {
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
  }

  .totalUsers {
    background-color: white;
    width: 14%;
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: 5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    .number {
      color: #3f4254;
      font-size: 44px;
      text-align: left;
      margin-block-start: 2rem;
    }

    img {
      width: 30px;
      height: 30px;
    }

    .text {
      font-size: 14px;
      color: #009ef7;
      font-weight: 800;
      margin-block-end: 2rem;
      text-align: left;
    }
  }

  .version-number {
    text-align: start;
    margin-block: 2rem;
    font-size: 1.5rem;
    font-weight: 800;
  }
}

@media (max-width: 767px) {
  .superUserDashboard {
    .totalUsers {
      width: 50%;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .superUserDashboard {
    .totalUsers {
      width: 100%;
    }
  }
}
