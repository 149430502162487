.footer-cont {
  background-color: #F4F6F8;
  padding: 10px 20px;
  color: rgb(179, 179, 179);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(239, 239, 239);

  @media only screen and (max-width: 680px) {
    flex-direction: column;
    text-align: center;

    .left-float {
      width: 100%;
      margin-top: 10px;

      span {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 10px;
        a {
            font-size: 14px !important;
        }
      }
    }

    .right-float {
      margin-top: 10px;

      span {
        .copyright {
          font-size: 8px !important; 
        }
    
      }
    }
  }

  .left-float {
    span {
      display: flex;
      gap: 10px;

      a {
        font-size: 12px;
        color: rgb(164, 164, 164);
        cursor: pointer;

        &:hover {
          color: rgb(109, 109, 109);
        }
      }
    }
  }

  .right-float {
    span {
        .copyright {
          font-size: 12px; 
        }
    }
  }
}
