.ctn {
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: all 0.2s ease-in-out;
  z-index: 10;
  background-color: #ffffffae;
    backdrop-filter: blur(7px);
  // transition-delay: .1s;

  .top-nav {
    // padding: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.096);
    padding-left: 1.5rem;

    .top-nav-left {
      align-items: center;
      font-size: 14px;
      color: rgb(93, 93, 93);
      display: flex;
      font-weight: 500;
      transition: all 0.3s ease;
      width: 100%;

      .each-left-head {
        margin-left: 10px;
        align-items: center;
        display: flex;

        span {
          align-items: center;
        }

        svg {
          margin: 0;
          margin-inline-end: 7px;

          path {
            fill: rgb(93, 93, 93);
          }
        }
      }
    }

    .top-nav-right {
      justify-self: start;
      margin-left: 10px;
      cursor: pointer;
      list-style: none;
      text-decoration: none;
      font-size: 1.5rem;
      display: flex;
      align-items: center;

      .nav-items {
        font-size: 14px;
        text-decoration: none;
      }

      .signin {
        background-color: #52ab98;
        height: 100%;
        width: 150px;
        text-align: center;
        font-size: 14px;
        align-items: center;
        padding: 10px;
        transition: all 0.4s ease-in-out;

        span {
          font-size: 16px;
          color: rgb(240, 240, 240);
          text-decoration: none;
          text-transform: none;
          align-items: center;
          width: 500px;
          font-weight: 900;
        }

        &:hover {
          background-color: white;

          span {
            color: #2b6777;
          }
        }
      }

      .register {
        background-color: #2b6777;
        width: 200px;
        height: 100%;
        font-size: 14px;
        padding: 5px;
        text-align: center;
        align-items: center;
        padding: 10px;
        transition: all 0.4s ease-in-out;
        font-weight: 600;

        &:hover {
          background-color: #1c4a57;

          span {
            color: rgb(253, 253, 253);
          }
        }

        span {
          color: rgb(223, 223, 223);
          text-decoration: none;
          text-transform: none;
          align-items: center;
          width: 500px;
        }
      }
    }
  }

  .btm-nav {
    width: 100%;
    // height: 70px;
    align-items: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    // justify-content: space-evenly;

    .logo-container {
      // width: 100px;
      height: 100px;
      position: relative;
      float: left;
      padding-left: 40px;
      display: flex;
      justify-content: center;
  transition: all .1s ease-in-out;

      .logo {
        display: flex;
        align-items: center;

        img {
          width: 100px;
          margin: 10px;
        }
      }
    }

    .nav-link-ctn {
      display: flex;
      align-items: flex-end;
      margin-left: auto;
      padding-right: 40px;
      

      ul {
        text-decoration: none;
        list-style: none;
        display: flex;
        justify-content: center;

        li {
          padding: 0 20px;
          align-items: center;
          display: flex;

          a {
            color: #a2a2a2;
            font-size: 16px;
            font-weight: 400;

            &:hover {
              color: #52ab98;
            }

            svg {
              fill: #a2a2a2;
              stroke: none;
              transition: all 0.4s ease-in-out;
              // stroke-width: 0px;

              &:hover {
                fill: #52ab98;
              }
            }


            img {
              width: 30px;
            }
          }
        }
      }
    }
  }
}

.ctn.active {
  position: fixed;
  width: 100%;
  background-color: #bdbdbdae;
  transition: all 0.1s ease-in-out;

  .top-nav {
    // padding: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: transparent;
    padding-left: 1.5rem;
    transition: all 0.2s ease-in-out;
    transition-delay: 0.1s;

    .top-nav-left {
      align-items: center;
      font-size: 14px;
      // font-family: "Axiforma";
      color: #1c4a57;
      display: flex;
      font-weight: 500;
      transition: all 0.2s ease-in;
      transition-delay: 0.1s;
      width: 100%;

      .each-left-head {
        margin-left: 10px;
        align-items: center;
        display: flex;

        span {
          align-items: center;
        }

        svg {
          margin: 0;
          margin-inline-end: 7px;

          path {
            fill: #1c4a57;
          }
        }
      }
    }

    .top-nav-right {
      justify-self: start;
      margin-left: 10px;
      cursor: pointer;
      list-style: none;
      text-decoration: none;
      font-size: 1.5rem;
      display: flex;
      align-items: center;

      .nav-items {
        font-size: 14px;
        text-decoration: none;
      }
    }
  }

  .btm-nav {
    padding: 10px;
    align-items: center;
    transition: all 0.2s ease-in-out;
    transition-delay: 0.1s;

    .logo-container {
      // width: 100px;
      height: 60px;
      position: relative;
      float: left;
      padding-left: 40px;
      display: flex;
      justify-content: center;

      .logo {
        display: flex;
        align-items: center;

        img {
          width: 50px;
          margin: 10px;
    transition: all .1s ease-in-out;
        }
      }
    }

    .nav-link-ctn {
      display: flex;
      align-items: flex-end;
      margin-left: auto;
      padding-right: 40px;
      transition: all 0.2s ease-in-out;

      ul {
        margin: 5px 0;
      transition: all .2s ease-in-out;

        li {
          padding: 0 20px;
          align-items: center;
          display: flex;
          transition: all 0.2s ease-in-out;

          a {
            color: #52ab98;
            font-size: 16px;
            font-weight: 400;
            transition: all 0.2s ease-in-out;

            &:hover {
              color: #2b6777;
            }

            svg,
            svg path {
              fill: #52ab98;
              stroke: none;
              transition: all 0.4s ease-in-out;
              // stroke-width: 0px;

              &:hover {
                fill: #2b6777;
              }
            }

            img {
              width: 30px;
            }
          }
        }
      }
    }
  }
}

.nav {
  display: none;
}

.activeBg {
  background-color: #1c4a57;
  padding: 10px;
  font-size: 16px;
  font-weight: 800;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
}

@media (max-width: 420px) {
  .ctnn {
    display: block;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    transition: all 0.2s ease-in-out;
    z-index: 999;
  }

  .ctnn.activee {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    transition: all 0.1s ease-in-out;
    padding-top: 0px;
  }

  .ctn {
    display: none;
  }

  .nav {
    display: flex;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.2);
    height: 75px;
  }

  .logo img {
    width: 50px;
    height: 50px;
    margin-top: 15px;
    margin-left: 30px;
  }

  .nav-links {
    display: none;
  }

  a {
    text-decoration: none;
    font-size: 22px;
  }

  li {
    list-style-type: none;
  }

  .nav-links-mobile {
    position: absolute;
    display: block;
    top: 90%;
    left: 0;
    list-style: none;
    box-shadow: rgba(252, 252, 255, 0.23) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    transition: all 0.5s ease-in-out;
    width: 100%;
    height: 100vh;
    background-color: #52ab98;
  }

  .nav-links-mobile li {
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
  }

  ul li {
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: left;
    color: black;
    // font-family: "Karla";
    font-size: 30px;
    padding-top: 20px;
    font-weight: 900;
    padding-left: 30px;
  }

  .header1 {
    animation: slideUpFromBottom 500ms;
  }

  .header2 {
    animation: slideUpFromBottom 700ms;
  }

  .header3 {
    animation: slideUpFromBottom 900ms;
  }

  .header4 {
    animation: slideUpFromBottom 1100ms;
  }

  .header5 {
    animation: slideUpFromBottom 1300ms;
  }

  .header6 {
    animation: slideUpFromBottom 1500ms;
  }

  .header7 {
    animation: slideUpFromBottom 1700ms;
  }

  .header8 {
    animation: slideUpFromBottom 1900ms;
  }

  ul li:hover {
    color: grey;
  }

  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 30px;
    color: white;
  }

  .bars {
    width: 50px;
    height: 50px;
    color: white;
    margin-top: 10px;
  }

  .activeBgg {
    background-color: #1c4a57;
    border-radius: 10px;
    padding: 10px;
    color: white;
  }

  .specialistTalk {
    display: flex;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideUpFromBottom {
  0% {
    transform: translateY(70%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
