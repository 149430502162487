@keyframes leaves {

  0%,
  50% {
    transform: scale(0.8);
    opacity: 1;
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

.homepage {
  // height: calc(100% - 6.5rem - 5.8rem);
  display: block;
  justify-content: space-between;

  .fixed-nof {
    position: fixed;
    bottom: 8%;
    right: 8%;
    padding: 10px;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    display: flex;
    z-index: 2;
    text-align: center;

    @media screen and (max-width: 660px) {
      right: 4%;
    }

    .loading {
      position: relative;
      width: 70px;
      height: 70px;
      justify-content: center;
      align-items: center;

      .bounce1 {
        position: absolute;
        height: 70px;
        width: 70px;
        background-color: #2b6777;
        border-radius: 100%;
        opacity: 0.6;
        top: 0px;
        left: 0px;
        // animation: 1.2s ease-in 1s infinite reverse both running leaves;
        animation-name: leaves;
        animation-duration: 1.2s;
        animation-timing-function: ease;
        animation-delay: 1s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-play-state: running;
      }

      // .bounce2 {
      //     position: absolute;
      //     height: 120px;
      //     width: 120px;
      //     background-color: rgb(54, 215, 183);
      //     border-radius: 100%;
      //     opacity: 0.6;
      //     top: 0px;
      //     left: 0px;
      //     animation: 2.1s ease-in-out 0s infinite normal none running leaves;
      // }
    }

    span {
      position: absolute;
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      color: white;
      font-weight: 600;
      width: 70px;
      height: 70px;
      font-size: 15px;
      background-color: #2b6777;
      border-radius: 50%;
    }
  }
}

.loader {
  align-items: center;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 100%;
  left: 50%;
  top: 35%;
}