@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Poppins&display=swap');

.signinFlex {
  display: flex;
  justify-content: center;
  background-color: #0f362e;
  height: 100vh;
  // 52ab98

 .err{
  color: rgb(107, 11, 11);
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  text-align: center;
 }

  .left-clm {
    width: 60%;
    height: 100%;
    padding: 3rem;

    @media only screen and (max-width: 680px) {
        display: none;
      }

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 20%;
      }

      h1 {
        color: white;
        font-weight: 900;
        margin-left: 20px;
      }
    }

    .left-center {}
  }

  .right-clm {
    background-color: #52ab98;
    width: 40%;
    // height: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .back_btn {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
    
        a {
          cursor: pointer;
    
          svg {
            transition: all 0.2s ease-in-out;
    
            &:hover {
              width: 35px;
              height: 35px;
            }
          }
        }
      }

    @media only screen and (max-width: 680px) {
          width: 100%;
            height: 100vh;
      }

    .right-clm-inner {
      // text-align: center;
      width: 100%;

      h1 {
        text-align: center;
        text-transform: capitalize;
        color: white;
        font-weight: 900;
      }

      .signin-form-content {
        .each-input {
          display: block;
          justify-content: center;
          margin-top: 20px;
          text-align: center;

          // padding-left: 12px;
          // padding: 10px 5px;
          // margin: 5px 5px  10px auto;

          .passerr {
            color: #f05050;
            font-size: 12px;
            margin-top: 10px;
            font-weight: 500;
          }

          .passerr.active {
            display: none;
          }

          .form-flex-cont {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
              display: block;

              .form-flex {
                margin-top: 8px;
              }
            }
          }

          .form-flex {
            border: 1.2px solid #235e51;
            background-color: white;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin-top: 5px;
            width: 80%;
            margin: 0 auto;

            select {
              border: none;
              padding: 12px;
              font-size: 16px;
              -moz-appearance: none;
              -webkit-appearance: none;
              width: 100%;
              background-color: transparent;
              background: transparent;

              option {
                margin: 10px 0;
              }
            }

            select::-ms-expand {
              display: none;
              border: none;
            }

            span {
              padding: 12px;
              font-weight: 700;
              color: #235e51;
            }

            .visibility-btn {
              width: 43px;
              height: 44px;
              background: #3381705d;
              align-items: center;
              display: flex;
              justify-content: center;
              border: none;

              .toggle-Icn {
                width: 100%;
                display: flex;
                justify-content: center;

                .fa1 {
                  display: inline-block;
                  font: normal normal normal 14px/1 FontAwesome;
                  font-size: inherit;
                  text-rendering: auto;
                  -webkit-font-smoothing: antialiased;
                  width: 14px;
                  height: 14px;
                  opacity: 0.7;
                }

                .fa1.active {
                  display: none;
                }
              }
            }
          }
        }

                .center {
                  align-items: center;
                }
      }

      .right-clm-btm {
        position: absolute;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        // z-index: 10;
        margin: 0 auto;
        bottom: 1rem;
        left: 0;
        // left: 50%;


        .logo {
          text-align: center;
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;

          p {
            color: white;
            margin-left: 10px;
          }

          img {
            width: 20%;

@media only screen and (max-width: 680px) {
    width: auto;
  }
          }
        }

        .flex {
          // padding: 0 20px ;
          // margin: 0 20px ;
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 20px;

          span {
            font-size: 15px;

            a{
              @media only screen and (max-width: 680px) {
                  font-size: 14px;
                }
            }
          }
        }
      }
    }
  }
}



@media only screen and (max-width: 980px) {
  .links a {
    font-size: 1.5rem;
  }
}

.err {
  li {
    list-style: none;
    color: rgb(255, 37, 37);
  }
}


@media only screen and (max-width: 420px) {

  .logo-container {
    width: 100%;
    height: 50px;
    padding-left: 40px;
    display: flex;
    justify-content: left;
    padding-bottom: 20px;
    padding-left: 20px;

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        margin: 10px;
        height: 50px;
      }
    }
  }

}