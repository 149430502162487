.expenses-container {
    display: block;
    padding: 10px 3rem;

    @media (max-width: 768px) {
            padding: 0px;
        }

    .page-url-cont {
        display: block;
        padding: 10px;

        .page-url {
            display: flex;
            align-items: center;

            span {
                font-size: 15px;
                font-weight: 500;
                padding: 0 5px 0 0;
            }

            .slash {
                color: rgb(204, 204, 204);
            }

            .colored {
                color: #00d890;
                cursor: pointer;
            }

            .mainurl {
                color: #000000;
            }
        }
    }

         .column1 {
             display: flex;
             width: 100%;
             justify-content: right;
             align-items: center;

            @media (max-width: 768px) {
                    justify-content: center;
                }
    
             .each-box {
                 padding: 20px 40px;
                 background-color: rgb(6, 112, 57);
                 box-shadow: 0 8px 13px rgba(0, 0, 0, 0.116);
                 text-align: center;
                 border-radius: 10px;
    
                 .box-head {
                     color: rgb(225, 225, 225);
                 }
    
                 .box-content {
                     color: rgb(255, 255, 255);
                     font-size: 25px;
                     font-weight: 600;
                     margin-top: 10px;
                 }
             }
         }

    .pos-sales-content-container {
        background-color: white;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.24);
        margin: 20px 0px 10px 0px;
        padding: 20px 0 20px 0;
        width: 100%;
        border-radius: 5px;
        display: block;
        overflow-x: hidden;

        .tab-controls {
            

            ul {
                display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-right: 20px;

                    

                li {
                    list-style: none;
                    padding: 0 20px;

                    span {
                        font-size: 14px;
                        font-weight: 600;
                        color: #686868;
                        text-transform: uppercase;
                        padding-bottom: 6px;
                        transition: all .4s ease-in-out;
                        cursor: pointer;

                        &:hover {
                            color: #00bb8c;
                        }
                    }

                    .tab1.active {
                        border-bottom: 4px solid #007558;
                        border-bottom-left-radius: 2px;
                        border-bottom-right-radius: 2px;
                        color: #00bb8c;
                    }
                }
            }
        }

        .tab-content {
            color: #777;
            padding: 20px !important;

            .tab-pane.active {
                display: none;
            }

            .tab-pane {
                display: block;

                .table-responsive {
                    display: block;
                    width: 100%;
                    overflow-x: auto;
                    border: 0 solid #ebeff2;
                    overflow: auto;

                    table {
                        border-spacing: 0;
                        border-collapse: collapse;

                        tbody tr .text-center {
                            text-align: center;

                            .center{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                            }

                            .font-bold {
                                font-weight: 600;
                            }

                        }

                        tbody tr {
                            border-bottom: 1px rgb(158, 158, 158) solid;
                            margin: 20px 0;
                        }

                                                .action-btn {
                                                    color: white;
                                                    background-color: rgb(19, 128, 55);
                                                    border-radius: 10px;
                                                    // width: 40%;
                                                    margin: 0 auto;
                                                    cursor: pointer;
                                                    font-size: 15px;
                                                    padding: 10px 0;
                        
                                                    &:hover {
                                                        background-color: red;
                                                    }
                                                }
                    }

                    .table {
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 20px;

                        .thead-light th {
                            color: #6c757d;
                            background-color: #f8f6ff;
                            border-color: #dee2e6;
                            white-space: nowrap;
                            padding: 8px;
                            line-height: 1.42857143;
                            border: 0 !important;
                        }
                    }
                }
            }
        }
    }
}