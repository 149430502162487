@tailwind base;
@tailwind components;
@tailwind utilities;

@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

@keyframes moveInFromBtm {
  0% {
    bottom: -100px;
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    bottom: 25px;
    opacity: 1;
  }
}

@keyframes moveBckToBtm {
  0% {
    bottom: 25px;
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    bottom: -100px;
    opacity: 0;
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Poppins&display=swap");

.loading-overlayy {
  background-color: rgba(0, 0, 0, 0.699);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .topprogressbarr {
    border-top: 5px solid #009c75;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    position: fixed;
    width: 0%;
    height: 5px;
    top: 0;
    left: 0;
    animation: loading-width 10s infinite;

    @keyframes loading-width {
      0% {
        width: 0%;
      }

      100% {
        width: 100%;
      }
    }
  }

  .lds-hourglasss {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .lds-hourglasss:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #fff;
    border-color: #fff transparent #009c75 transparent;
    animation: lds-hourglass 1.2s infinite;
  }

  @keyframes lds-hourglasss {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    100% {
      transform: rotate(1800deg);
    }
  }
}

html {
  font-size: 10px;
  font-family: "Poppins", "sans-serif";
  // user-select: none;
}

html,
body {
  padding: 0;
  margin: 0;
  // background-color: #ffffff;
  font-family: "Poppins", "sans-serif";
}

span{
  font-family: "Poppins", "sans-serif";
}

html,
body,
#root,
.App,
.fullHeight {
  height: 100%;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Poppins";
  font-size: 1.8rem;
  // font-weight: 400;
  // line-height: 1;
  // background-image: url("./assets/mposbg1.jpg");
  // background-blend-mode: darken;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-color: rgb(29, 175, 141);
  // background: white;
  height: 100vh;
}

h1 {
  display: block;
  width: 100%;
  padding: 0;
  // margin: 0 auto 2rem;
  font-size: 3rem;
  line-height: 3.2rem;
  color: #000000;
  text-align: left;
  font-weight: 400;
  text-transform: uppercase;
  // border-bottom: #d3d3d3;
}

h2 {
  display: block;
  width: 100%;
  padding: 0;
  // margin: 0 auto 2rem;
  font-size: 2.5rem;
  line-height: 2.7rem;
  color: #000000;
  text-align: left;
  font-weight: 400;
  text-transform: uppercase;
  // border-bottom: #d3d3d3;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a,
a:hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

link {
  text-decoration: none;
  cursor: pointer;
}

link,
link:hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

// .main {
//   width: 100%;
//   height: calc(100% - 6.5rem);
//   max-width: 1450px;
//   padding: 0 10px;
//   margin: 0 auto;
// }

.controlPanel {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 1rem 0 6rem 25rem;
  // border-bottom: 1px solid #d3d3d3;

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;

    // @media screen and (max-width: 660px) {
    //     height: 2px;
    //   }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(230, 230, 230);
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #00644b;
    border-radius: 10px;
  }

  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 25rem;
    height: 100%;
    z-index: 1;
    overflow-y: hidden;

    // text-align: center;
    // border-right: 1px solid #d3d3d3;

    &:hover {
      overflow-y: scroll;
    }
  }

  .content {
    padding: 0 10px;

    @media (max-width: 767px) {
          padding: 0px;
      }
  }
}

.adminLayout {
  // height: calc(100% - 6.5rem - 5.8rem - 3.6rem);
  // height: 100%;
}

.dashboardLayout {
  height: calc(100% - 6.5rem - 5.8rem);
}

@media only screen and (max-width: 980px) {
  html {
    font-size: 9px;
  }

  .controlPanel {
    padding: 0 10px;

    .sidebar {
      position: relative;
      width: 100%;
      border: none;
      margin-bottom: 2.5rem;
    }
  }
}

@media (max-width: 420px) {
  html,
  body,
  #root,
  .App,
  .fullHeight {
    height: 100vh;
  }

  .loading-overlayy {
    background-color: rgba(0, 0, 0, 0.699);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    .topprogressbarr {
      border-top: 5px solid #009c75;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      position: fixed;
      width: 0%;
      height: 5px;
      top: 0;
      left: 0;
      animation: loading-width 10s infinite;

      @keyframes loading-width {
        0% {
          width: 0%;
        }

        100% {
          width: 100%;
        }
      }
    }

    .lds-hourglasss {
      display: inline-block;
      width: 80px;
      height: 80px;
      position: absolute;
      left: 42%;
      top: 50%;
    }

    .lds-hourglasss:after {
      content: " ";
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: 0px;
      box-sizing: border-box;
      border: 32px solid #fff;
      border-color: #fff transparent #009c75 transparent;
      animation: lds-hourglass 1.2s infinite;
    }

    @keyframes lds-hourglasss {
      0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }

      50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      100% {
        transform: rotate(1800deg);
      }
    }
  }
}
