.company-profile-cont{
    display: block;
    padding: 10px 3rem;

    .page-url-cont{
        display: block;
        // padding: 10px;

        .page-url{
            display: flex;
                align-items: center;

            span{
                font-size: 25px;
                font-weight: 600;
                // padding: 0 5px 0 0;
            }

            .slash{
                color: rgb(204, 204, 204);
            }
            .colored{
                color:#035539;
                cursor: pointer;
            }

            .mainurl{
                color:#000000;
            }
        }
    }

         .tab-controls {
          // background-color: white;
    // box-shadow: 0 6px 10px rgba(0, 0, 0, 0.154);
    border-bottom: rgb(170, 170, 170) solid 0.7px;
    // border-radius: 10px;
    // padding-bottom: 10px;

           ul {
             display: flex;
             align-items: center;
             justify-content: space-between;
            //  padding: 6px 0;
    
             li {
               list-style: none;
              //  padding: 0 20px;
               display: flex;
               align-items: center;
              @media only screen and (max-width: 680px) {
                  padding: 0px;
                }
    
               input {
                 // border: 1px solid black;
                 margin-right: 15px;
                 box-shadow: 0 0px 4px rgba(0, 0, 0, 0.24);
                 border-radius: 5px;
                 padding: 5px;
                 font-size: 15px;
               }
    
               div {
                 padding-right: 20px;
               }
    
               span {
                 font-size: 14px;
                 font-weight: 600;
                 color: #686868;
                 text-transform: uppercase;
                //  padding-bottom: 6px;
                 transition: all .4s ease-in-out;
                 cursor: pointer;
    
                 &:hover {
                   color: #00bb8c;
                 }
               }
    
               .tab1.active {
                 border-bottom: 3px solid #007558;
                //  border-bottom-left-radius: 6px;
                //  border-bottom-right-radius: 6px;
                 color: #00bb8c;
               }
             }
           }
    
           select {
             margin-right: 15px;
             box-shadow: 0 0px 4px rgba(0, 0, 0, 0.24);
             border-radius: 5px;
             padding: 5px;
             font-size: 15px;
           }
    
           .refresh_btn {
             // position: absolute;
             // right: 20px;
             // cursor: pointer;
             background-color: #eaeaea;
             border-radius: 100px;
             padding: 5px;
             cursor: pointer;
             transition: all .2s ease-in-out;
    
             &:hover {
               background-color: #aaaaaa;
             }
           }
         }

                 .tab-content {
                   color: #777;
                   padding: 20px !important;
                   background-color: white;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.154);
    border-radius: 15px;
        margin: 6rem 0px 10px 0px;

        @media only screen and (max-width: 680px) {
            padding: 0px;
            margin: 10px 0;
          }
        
                   .tab-pane.active {
                     display: none;
                   }
        
                   .tab-pane {
                     display: block;
        
                     .table-responsive {
                       display: block;
                       width: 100%;
                       overflow-x: auto;
                       border: 0 solid #ebeff2;
                       overflow: auto;
        
                       table {
                         border-spacing: 0;
                         border-collapse: collapse;
        
                         tbody tr .text-center {
                           text-align: center;
        
                           .font-bold {
                             font-weight: 600;
                           }
        
                         }
        
                         tbody tr {
                           border-bottom: 1px rgb(158, 158, 158) solid;
                           margin: 20px 0;
                         }
        
                         .action-btn {
                           color: white;
                           background-color: rgb(19, 128, 55);
                           border-radius: 10px;
                           // width: 40%;
                           margin: 0 auto;
                           cursor: pointer;
                           font-size: 15px;
                           padding: 10px 0;
        
                           &:hover {
                             background-color: red;
                           }
                         }
                       }
        
                       .table {
                         width: 100%;
                         max-width: 100%;
                         margin-bottom: 20px;
        
                         .thead-light th {
                           color: #6c757d;
                           background-color: #f8f6ff;
                           border-color: #dee2e6;
                           white-space: nowrap;
                           padding: 8px;
                           line-height: 1.42857143;
                           border: 0 !important;
                         }
                       }
                     }
                   }
                 }

    .profile-content{
    // margin: 5px 0px 10px 0px;
    // padding: 20px 0 20px 0;
    width: 100%;
    display: block;

        .head{
            padding: 10px;
            font-weight: 600;
            font-size: 20px;
            color: rgb(158, 158, 158);
        }

        .store-profile-content-main{
          display: block;
          background-color: white;
            box-shadow: 0 0px 4px rgba(0, 0, 0, 0.24);
            margin: 6rem 0px 10px 0px;
            border-radius: 15px;
            padding: 10px;
      }

        .store-profile-content{
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            

            .logo-settings{
                display: block;

                .logo-cont{
                    padding: 10px 10px;

                    img{
                      width: 150px;
                      height: 150px;
                      border-radius: 50%;
                      transition: transform .2s;
                      object-fit: cover;

                      @media only screen and (max-width: 680px) {
                          width: 100px;
                            height: 100px;
                        }

                      &:hover{
                        transform: scale(1.1);
                      }
                    }

                    .fa-pencil{
                        text-align: center;
                        background-color: #00d890;
                        padding: 10px;
                        border-radius: 5px;
                        color: white;
                        transition: transform .5s;
                        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.144);

                        &:hover{
                            color: white;
                            background-color: #009c68;
                        }
                    }
                }
                
                span{
                    font-size: 14px;
                    color: #00d890;
                    font-weight: 600;
                }

                .fa-building{
                    background-color: #009c6894;
                    padding: 20px 30px;
                    border-radius: 100%;
                    font-size: 10rem;
                    color: white;
                }

                // img{
                //     width: 200px;
                //     padding: 10px;
                //     background-color: #009c684b;
                //     padding: 10px;
                //     border-radius: 100%;
                // }


            }

            .other-settings{
                .each-input {
                    display: block;
                    justify-content: center;
                    margin-top: 20px;

                    .input-heading{
                        font-size: 14px;
                        font-weight: 600;
                        color: #009c68;
                    }

                    .red{
                        color: red;
                        font-weight: 500;
                    }

                    .form-flex-cont{
                      display: flex;
                      justify-content: space-between;
                
                      @media screen and (max-width: 768px) {
                        display: block;
                        .form-flex{
                          margin-top: 8px;
                        }
                      }
                    }
                    
                    .form-flex{
                      border: 1.2px solid #235e51;
                    border-radius: 5px;
                      display: flex;
                      align-items: center;
                      margin-top: 5px;
                
                      select{
                        border: none;
                        padding: 12px;
                        font-size: 16px;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        width: 100%;
                        background-color: transparent;
                        background: transparent;
                
                        option{
                          margin: 10px 0;
                        }
                      }
                
                      select::-ms-expand{
                        display: none;
                        border: none;
                      }
                
                      span{
                        padding: 12px;
                        font-weight: 700;
                        color: #235e51;
                      }
                
                      .visibility-btn{
                        width: 43px;
                        height: 44px;
                        background: #3381705d;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        border: none;
                
                        .toggle-Icn{
                          width: 100%;
                          display: flex;
                          justify-content: center;
                
                          .fa1{
                            display: inline-block;
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    width: 14px;
                    height: 14px;
                    opacity: 0.7;
                          }
                
                          .fa1.active{
                            display: none;
                          }
                        }
                      }
                    }
                  }
            }
        }
    }
}